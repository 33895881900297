import React from 'react';
import ChipRequestForm from '../components/forms/ChipRequestForm';

const RequestChip: React.FC = () => {
  return (
    <div className="py-16 px-4">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Solicitar Chip UaiSat</h1>
          <p className="text-xl text-gray-600">
            Preencha o formulário abaixo para solicitar seu chip UaiSat
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-xl p-8">
          <ChipRequestForm />
        </div>

        <div className="mt-8 text-sm text-gray-500 text-center">
          <p>
            Ao solicitar o chip, você concorda com nossos{' '}
            <a href="/termos-e-condicoes" className="text-uai-teal hover:text-uai-dark">
              Termos e Condições
            </a>{' '}
            e{' '}
            <a href="/politica-de-privacidade" className="text-uai-teal hover:text-uai-dark">
              Política de Privacidade
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RequestChip;