import emailjs from '@emailjs/browser';

// Initialize EmailJS with public key from environment variable
const publicKey = import.meta.env.VITE_EMAILJS_PUBLIC_KEY;
const serviceId = import.meta.env.VITE_EMAILJS_SERVICE_ID;

if (!publicKey) {
  throw new Error('EmailJS public key not found in environment variables');
}

if (!serviceId) {
  throw new Error('EmailJS service ID not found in environment variables');
}

emailjs.init({
  publicKey,
  blockHeadless: false, // For development environment
  limitRate: true
});

// Email templates
export const TEMPLATES = {
  CONTACT: import.meta.env.VITE_EMAILJS_TEMPLATE_CONTACT || 'template_contact_uai',
  NEWSLETTER: import.meta.env.VITE_EMAILJS_TEMPLATE_NEWSLETTER || 'template_newsletter_uai',
  BUSINESS: import.meta.env.VITE_EMAILJS_TEMPLATE_BUSINESS || 'template_business_uai',
  SUPPORT: import.meta.env.VITE_EMAILJS_TEMPLATE_SUPPORT || 'template_support_uai',
  CAREER: import.meta.env.VITE_EMAILJS_TEMPLATE_CAREER || 'template_career_uai'
} as const;

type EmailTemplate = typeof TEMPLATES[keyof typeof TEMPLATES];

interface EmailParams {
  templateId: EmailTemplate;
  templateParams: Record<string, unknown>;
}

// Function to send email with better error handling
export const sendEmail = async ({ templateId, templateParams }: EmailParams) => {
  try {
    if (!templateId) {
      throw new Error('Template ID is required');
    }

    const response = await emailjs.send(
      serviceId,
      templateId,
      {
        ...templateParams,
        site_name: 'UaiSat',
        site_url: window.location.origin
      }
    );

    if (response.status !== 200) {
      throw new Error(`EmailJS request failed with status ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error('Error sending email:', error);
    
    // Provide more descriptive error messages
    if (error instanceof Error) {
      if (error.message.includes('public key')) {
        throw new Error('Invalid EmailJS configuration. Please check your environment variables.');
      }
      throw error;
    }
    
    throw new Error('Failed to send email. Please try again later.');
  }
};