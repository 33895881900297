import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Bell, Shield, HelpCircle, Info, ChevronRight, User } from 'lucide-react';

const Settings: React.FC = () => {
  const navigate = useNavigate();
  
  const settings = [
    {
      icon: User,
      title: 'Perfil',
      description: 'Gerenciar informações pessoais',
      path: '/app/settings/profile'
    },
    {
      icon: Bell,
      title: 'Notificações',
      description: 'Configurar alertas e lembretes',
      path: '/app/settings/notifications'
    },
    {
      icon: Shield,
      title: 'Privacidade e Segurança',
      description: 'Gerenciar suas preferências de privacidade',
      path: '/app/settings/privacy'
    },
    {
      icon: HelpCircle,
      title: 'Ajuda e Suporte',
      description: 'Tire suas dúvidas e obtenha ajuda',
      path: '/app/settings/support'
    },
    {
      icon: Info,
      title: 'Sobre o App',
      description: 'Versão 1.0.0',
      path: '/app/settings/about'
    }
  ];

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Configurações</h1>

      <div className="bg-white rounded-2xl shadow-sm divide-y">
        {settings.map((setting, index) => {
          const Icon = setting.icon;
          return (
            <button
              key={index}
              onClick={() => navigate(setting.path)}
              className="w-full p-4 flex items-center hover:bg-gray-50 transition-colors"
            >
              <div className="w-10 h-10 rounded-full bg-uai-light flex items-center justify-center mr-4">
                <Icon className="w-5 h-5 text-uai-teal" />
              </div>
              <div className="flex-1 text-left">
                <p className="font-medium">{setting.title}</p>
                <p className="text-sm text-gray-500">{setting.description}</p>
              </div>
              <ChevronRight className="w-5 h-5 text-gray-400" />
            </button>
          );
        })}
      </div>

      <div className="mt-8 text-center text-sm text-gray-500">
        <p>UaiSat Recargas</p>
        <p>Versão 1.0.0</p>
      </div>
    </div>
  );
};

export default Settings;