import React, { useState } from 'react';
import { Star, User, Quote, Send, X } from 'lucide-react';
import TestimonialCard from './TestimonialCard';

const Testimonials: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    city: '',
    rating: 5,
    comment: ''
  });

  const testimonials = [
    {
      name: 'Carlos Silva',
      rating: 5,
      comment: 'Melhor operadora que já usei! Sinal excelente em toda a cidade e atendimento nota 10.',
      city: 'Uberlândia'
    },
    {
      name: 'Ana Oliveira',
      rating: 4,
      comment: 'Planos com ótimo custo-benefício e internet rápida. Recomendo!',
      city: 'Juiz de Fora'
    },
    {
      name: 'Roberto Almeida',
      rating: 5,
      comment: 'Desde que mudei para a UaiSat não tive mais problemas com sinal. Excelente cobertura!',
      city: 'Montes Claros'
    }
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the data to your backend
    console.log('Review submitted:', formData);
    toast.success('Obrigado pela sua avaliação!');
    setShowForm(false);
    setFormData({
      name: '',
      city: '',
      rating: 5,
      comment: ''
    });
  };

  return (
    <section className="py-16 px-4 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">O que nossos clientes dizem</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Descubra por que somos a operadora mais bem avaliada pelos clientes
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} testimonial={testimonial} />
          ))}
        </div>

        <div className="text-center mt-12">
          <button 
            onClick={() => setShowForm(true)}
            className="inline-flex items-center text-uai-teal hover:text-uai-dark font-bold text-lg transition-colors group"
          >
            Compartilhe sua experiência com a UaiSat
            <Star className="w-5 h-5 ml-2 fill-current transform transition-transform duration-300 group-hover:scale-110" />
          </button>
        </div>

        {/* Review Form Modal */}
        {showForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-2xl shadow-xl w-full max-w-lg relative">
              <button
                onClick={() => setShowForm(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>

              <div className="p-6">
                <h3 className="text-2xl font-bold mb-6">Deixe sua avaliação</h3>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Nome completo
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Cidade
                    </label>
                    <input
                      type="text"
                      value={formData.city}
                      onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                      className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Avaliação
                    </label>
                    <div className="flex gap-2">
                      {[1, 2, 3, 4, 5].map((rating) => (
                        <button
                          key={rating}
                          type="button"
                          onClick={() => setFormData({ ...formData, rating })}
                          className="focus:outline-none"
                        >
                          <Star
                            className={`w-8 h-8 ${
                              rating <= formData.rating
                                ? 'text-yellow-400 fill-yellow-400'
                                : 'text-gray-300'
                            }`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Seu comentário
                    </label>
                    <textarea
                      value={formData.comment}
                      onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
                      rows={4}
                      className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-3 px-6 rounded-lg transition-all duration-300 flex items-center justify-center group"
                  >
                    <span>Enviar avaliação</span>
                    <Send className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Testimonials;