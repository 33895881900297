import React, { useState } from 'react';
import { Send, Loader2 } from 'lucide-react';
import toast from 'react-hot-toast';
import { sendBusinessForm } from '../../lib/api';
import { IMaskInput } from 'react-imask';

interface BusinessFormProps {
  type: 'consultant' | 'proposal' | 'corporate';
  onSuccess?: () => void;
}

const BusinessForm: React.FC<BusinessFormProps> = ({ type, onSuccess }) => {
  const [formData, setFormData] = useState({
    company_name: '',
    contact_name: '',
    email: '',
    phone: '',
    employees: '',
    message: ''
  });

  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const getSubject = () => {
    switch (type) {
      case 'consultant':
        return 'Contato com Consultor';
      case 'proposal':
        return 'Solicitação de Proposta';
      case 'corporate':
        return 'Planos Corporativos';
      default:
        return 'Contato Empresarial';
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      // Format message to include company info and form type
      const formattedMessage = `Tipo de contato: ${getSubject()}\nEmpresa: ${formData.company_name}\nNúmero de funcionários: ${formData.employees}\n\n${formData.message}`;

      await sendBusinessForm({
        nome_completo: formData.contact_name,
        email: formData.email,
        telefone: formData.phone,
        empresa: formData.company_name,
        funcionarios: formData.employees,
        mensagem: formattedMessage
      });

      toast.success('Solicitação enviada com sucesso!');
      setFormData({
        company_name: '',
        contact_name: '',
        email: '',
        phone: '',
        employees: '',
        message: ''
      });
      onSuccess?.();
    } catch (error) {
      console.error('Error sending business form:', error);
      toast.error(error instanceof Error ? error.message : 'Erro ao enviar solicitação');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-white p-8 rounded-2xl shadow-xl">
      <h2 className="text-2xl font-bold mb-6">{getSubject()}</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="company_name" className="block text-sm font-medium text-gray-700 mb-2">
              Nome da empresa
            </label>
            <input
              type="text"
              id="company_name"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              required
            />
          </div>

          <div>
            <label htmlFor="contact_name" className="block text-sm font-medium text-gray-700 mb-2">
              Nome do responsável
            </label>
            <input
              type="text"
              id="contact_name"
              name="contact_name"
              value={formData.contact_name}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
              Email corporativo
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              required
            />
          </div>

          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-2">
              Telefone
            </label>
            <IMaskInput
              id="phone"
              name="phone"
              mask="(00) 00000-0000"
              value={formData.phone}
              unmask={true}
              onAccept={(value) => setFormData(prev => ({ ...prev, phone: value }))}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              required
            />
          </div>
        </div>

        <div>
          <label htmlFor="employees" className="block text-sm font-medium text-gray-700 mb-2">
            Número de funcionários
          </label>
          <select
            id="employees"
            name="employees"
            value={formData.employees}
            onChange={handleChange}
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
            required
          >
            <option value="">Selecione uma opção</option>
            <option value="1-10">1-10 funcionários</option>
            <option value="11-50">11-50 funcionários</option>
            <option value="51-200">51-200 funcionários</option>
            <option value="201-500">201-500 funcionários</option>
            <option value="501+">Mais de 500 funcionários</option>
          </select>
        </div>

        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">
            Mensagem
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows={4}
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
            required
          ></textarea>
        </div>

        <button
          type="submit"
          disabled={submitting}
          className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 flex items-center justify-center group disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {submitting ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
              Enviando...
            </>
          ) : (
            <>
              <Send className="w-5 h-5 mr-2 transform group-hover:translate-x-1 transition-transform duration-300" />
              Enviar solicitação
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default BusinessForm;