import React from 'react';
import { Outlet, useLocation, Link, useNavigate } from 'react-router-dom';
import { RefreshCw, Clock, Settings, LogOut } from 'lucide-react';
import { useAuth } from '../lib/auth';

const Layout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const navigation = [
    { name: 'Recarregar', href: '/app/recharge', icon: RefreshCw },
    { name: 'Histórico', href: '/app/history', icon: Clock },
    { name: 'Ajustes', href: '/app/settings', icon: Settings }
  ];

  const handleLogout = () => {
    logout();
    navigate('/app/login');
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/app/recharge':
        return 'Nova Recarga';
      case '/app/history':
        return 'Histórico';
      case '/app/settings':
        return 'Configurações';
      default:
        return '';
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow-sm fixed top-0 left-0 right-0 z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="h-16 flex items-center justify-between">
            <h1 className="text-xl font-semibold">{getPageTitle()}</h1>
            <img 
              src="https://cloud.uaisat.app.br/03/Logo-Grupo-UaiSat.png" 
              alt="UaiSat Logo" 
              className="h-8"
            />
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-1 pt-20 pb-20 px-4">
        <div className="max-w-7xl mx-auto">
          <Outlet />
        </div>
      </main>

      {/* Bottom Navigation */}
      <nav className="bg-white border-t fixed bottom-0 left-0 right-0 z-50">
        <div className="max-w-7xl mx-auto">
          <div className="flex justify-around">
            {navigation.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.href;
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`flex flex-col items-center py-3 px-4 transition-colors ${
                    isActive
                      ? 'text-uai-teal'
                      : 'text-gray-500 hover:text-gray-900'
                  }`}
                >
                  <Icon className={`w-6 h-6 ${isActive ? 'scale-110' : ''} transition-transform`} />
                  <span className="text-xs mt-1 font-medium">{item.name}</span>
                </Link>
              );
            })}
            <button
              onClick={handleLogout}
              className="flex flex-col items-center py-3 px-4 text-gray-500 hover:text-red-500 transition-colors"
            >
              <LogOut className="w-6 h-6 transition-transform" />
              <span className="text-xs mt-1 font-medium">Sair</span>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Layout;