import React from 'react';
import { Info, ArrowLeft, Github, Globe, Shield, Phone } from 'lucide-react';

const About: React.FC = () => {
  return (
    <div>
      <div className="flex items-center mb-6">
        <button 
          onClick={() => window.history.back()}
          className="mr-4 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="w-6 h-6" />
        </button>
        <h1 className="text-2xl font-bold">Sobre o App</h1>
      </div>

      <div className="space-y-6">
        {/* App Info */}
        <div className="bg-white rounded-2xl shadow-sm p-6 text-center">
          <img 
            src="https://cloud.uaisat.app.br/03/Logo-Grupo-UaiSat.png"
            alt="UaiSat Logo"
            className="h-16 mx-auto mb-4"
          />
          <h2 className="text-xl font-bold mb-2">UaiSat Recargas</h2>
          <p className="text-gray-500 mb-4">Versão 1.0.0</p>
          <div className="flex justify-center space-x-4">
            <a 
              href="https://uaimovel.com.br"
              target="_blank"
              rel="noopener noreferrer"
              className="text-uai-teal hover:text-uai-dark"
            >
              <Globe className="w-6 h-6" />
            </a>
            <a 
              href="https://github.com/uaisat"
              target="_blank"
              rel="noopener noreferrer"
              className="text-uai-teal hover:text-uai-dark"
            >
              <Github className="w-6 h-6" />
            </a>
          </div>
        </div>

        {/* Features */}
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Recursos</h2>
          <div className="space-y-4">
            <div className="flex items-start">
              <Phone className="w-5 h-5 text-uai-teal mr-3 mt-1" />
              <div>
                <p className="font-medium">Recargas rápidas e seguras</p>
                <p className="text-sm text-gray-500">Faça recargas de qualquer operadora em segundos</p>
              </div>
            </div>
            <div className="flex items-start">
              <Shield className="w-5 h-5 text-uai-teal mr-3 mt-1" />
              <div>
                <p className="font-medium">Pagamento seguro</p>
                <p className="text-sm text-gray-500">Suas transações são protegidas com criptografia de ponta a ponta</p>
              </div>
            </div>
          </div>
        </div>

        {/* Legal */}
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Legal</h2>
          <div className="space-y-4">
            <a 
              href="/termos-e-condicoes"
              className="block text-gray-700 hover:text-uai-teal"
            >
              Termos de Uso
            </a>
            <a 
              href="/politica-de-privacidade"
              className="block text-gray-700 hover:text-uai-teal"
            >
              Política de Privacidade
            </a>
            <a 
              href="/politica-de-privacidade#cookies"
              className="block text-gray-700 hover:text-uai-teal"
            >
              Política de Cookies
            </a>
          </div>
        </div>

        {/* Credits */}
        <div className="text-center text-sm text-gray-500 mt-8">
          <p>© 2024 UaiSat Telecomunicações</p>
          <p>Todos os direitos reservados</p>
        </div>
      </div>
    </div>
  );
};

export default About;