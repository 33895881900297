import React, { useState, useEffect } from 'react';
import { Download, X } from 'lucide-react';

const InstallBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  useEffect(() => {
    const handler = (e: any) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later
      setDeferredPrompt(e);
      // Show the banner
      setShowBanner(true);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstall = async () => {
    if (!deferredPrompt) return;

    // Show the install prompt
    deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice;
    
    // Clear the deferredPrompt variable
    setDeferredPrompt(null);
    
    // Hide the banner
    setShowBanner(false);

    // Optionally, send analytics event
    if (outcome === 'accepted') {
      console.log('User accepted the install prompt');
    } else {
      console.log('User dismissed the install prompt');
    }
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200 p-4 animate-slideUp">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <img 
            src="https://cloud.uaisat.app.br/03/favicon.png"
            alt="UaiSat"
            className="w-10 h-10 mr-4"
          />
          <div>
            <h3 className="font-bold">Instalar UaiSat Recargas</h3>
            <p className="text-sm text-gray-600">Adicione à tela inicial para acesso rápido</p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={() => setShowBanner(false)}
            className="p-2 text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
          <button
            onClick={handleInstall}
            className="bg-uai-teal hover:bg-uai-dark text-white px-4 py-2 rounded-lg flex items-center"
          >
            <Download className="w-5 h-5 mr-2" />
            Instalar
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstallBanner;