import React from 'react';
import { FileText, Download, Filter } from 'lucide-react';

const Reports: React.FC = () => {
  const reports = [
    {
      id: '1',
      name: 'Relatório de Vendas',
      description: 'Resumo de todas as recargas realizadas',
      date: '15/03/2024',
      type: 'PDF'
    },
    {
      id: '2',
      name: 'Relatório de Usuários',
      description: 'Lista de usuários cadastrados',
      date: '15/03/2024',
      type: 'XLSX'
    }
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Relatórios</h1>
        <button className="bg-uai-teal hover:bg-uai-dark text-white px-4 py-2 rounded-lg flex items-center">
          <Filter className="w-5 h-5 mr-2" />
          Filtrar
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {reports.map((report) => (
          <div key={report.id} className="bg-white rounded-2xl shadow-sm p-6">
            <div className="flex items-start justify-between">
              <div className="flex items-start">
                <div className="h-10 w-10 rounded-full bg-uai-light flex items-center justify-center">
                  <FileText className="h-5 w-5 text-uai-teal" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold">{report.name}</h3>
                  <p className="text-sm text-gray-500">{report.description}</p>
                  <p className="text-sm text-gray-500 mt-2">Gerado em: {report.date}</p>
                </div>
              </div>
              <button className="bg-uai-teal hover:bg-uai-dark text-white p-2 rounded-lg">
                <Download className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reports;