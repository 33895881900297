import React, { useState } from 'react';
import { Award, Users, MapPin, Target, Rocket, Heart, X } from 'lucide-react';
import CareerForm from '../components/forms/CareerForm';

const About: React.FC = () => {
  const [showForm, setShowForm] = useState(false);

  const stats = [
    { label: 'Clientes satisfeitos', value: '50K+' },
    { label: 'Cidades atendidas', value: '5000+' },
    { label: 'Anos no mercado', value: '10+' },
    { label: 'Colaboradores', value: '500+' }
  ];

  const values = [
    {
      icon: <Heart className="w-12 h-12 text-uai-teal" />,
      title: 'Foco no Cliente',
      description: 'Nosso compromisso é superar as expectativas dos nossos clientes em cada interação.'
    },
    {
      icon: <Target className="w-12 h-12 text-uai-teal" />,
      title: 'Inovação',
      description: 'Buscamos constantemente novas tecnologias e soluções para melhorar nossos serviços.'
    },
    {
      icon: <Rocket className="w-12 h-12 text-uai-teal" />,
      title: 'Excelência',
      description: 'Mantemos os mais altos padrões de qualidade em tudo o que fazemos.'
    }
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[500px] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            alt="About UaiSat"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4">
          <div className="max-w-2xl">
            <h1 className="text-5xl font-bold text-white mb-6">Sobre a UaiSat</h1>
            <p className="text-xl text-white/90">
              Conectando pessoas e transformando vidas através da melhor experiência em telecomunicações
            </p>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20 px-4 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <p className="text-4xl font-bold text-uai-teal mb-2">{stat.value}</p>
                <p className="text-gray-600">{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Story Section */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold mb-6">Nossa História</h2>
              <p className="text-lg text-gray-600 mb-6">
                Fundada em 2014, a UaiSat nasceu com a missão de revolucionar o mercado de telecomunicações no Brasil, oferecendo serviços de alta qualidade com o verdadeiro jeito mineiro de atender.
              </p>
              <p className="text-lg text-gray-600 mb-6">
                Começamos como uma pequena operadora regional e, graças à confiança de nossos clientes e ao trabalho dedicado de nossa equipe, expandimos nossa cobertura para todo o território nacional.
              </p>
              <p className="text-lg text-gray-600">
                Hoje, somos uma das operadoras que mais crescem no país, reconhecida pela qualidade dos nossos serviços e pelo atendimento humanizado. Nossa rede 4G+ está presente em mais de 5.000 municípios, e já oferecemos tecnologia 5G nas principais capitais.
              </p>
            </div>
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                alt="UaiSat History"
                className="rounded-2xl shadow-xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/10 to-transparent rounded-2xl" />
            </div>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Nossos Valores</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Os princípios que guiam nossas ações e decisões todos os dias
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {values.map((value, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-2xl shadow-card hover:shadow-xl transition-all duration-300 group hover:-translate-y-1"
              >
                <div className="mb-6 transform group-hover:scale-110 transition-transform duration-300">
                  {value.icon}
                </div>
                <h3 className="text-2xl font-bold mb-3">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20 px-4 bg-gradient-to-r from-uai-dark to-uai-teal text-white">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6">Faça Parte do Nosso Time</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Estamos sempre em busca de talentos que queiram fazer a diferença e crescer conosco. Envie seu currículo e faça parte dessa história de sucesso!
          </p>
          <button 
            onClick={() => setShowForm(true)}
            className="bg-white text-uai-teal hover:bg-gray-100 font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 inline-flex items-center group"
          >
            <span>Enviar currículo</span>
            <svg 
              className="w-5 h-5 ml-2 transform transition-transform duration-300 group-hover:translate-x-1" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                d="M5 12H19M19 12L12 5M19 12L12 19" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </section>

      {/* Career Form Modal */}
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="w-full max-w-2xl">
            <button
              onClick={() => setShowForm(false)}
              className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
            <CareerForm onSuccess={() => setShowForm(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default About;