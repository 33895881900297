import React from 'react';
import { 
  Phone, 
  Mail, 
  MapPin, 
  Facebook, 
  Instagram,
  Youtube,
  Smartphone,
  CreditCard,
  MapPin as MapPinIcon,
  ArrowRight,
  Wifi,
  Store,
  Users
} from 'lucide-react';
import { Link } from 'react-router-dom';
import NewsletterForm from './forms/NewsletterForm';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Newsletter Section */}
        <div className="bg-uai-dark rounded-lg p-6 mb-12">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="mb-6 md:mb-0 md:mr-8">
              <h3 className="text-xl font-bold mb-2">Fique por dentro das novidades</h3>
              <p className="text-gray-300">Receba ofertas exclusivas e novidades diretamente no seu email</p>
            </div>
            <div className="w-full md:w-auto">
              <NewsletterForm />
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Column 1 - About */}
          <div>
            <div className="flex items-center mb-6">
              <img 
                src="https://cloud.uaisat.app.br/03/Logo-Grupo-UaiSat.png" 
                alt="UaiSat Logo" 
                className="h-10"
              />
            </div>
            <p className="text-gray-400 mb-6">
              A UaiSat é a operadora de telefonia móvel que oferece a melhor experiência em conectividade, com planos flexíveis e cobertura em todo o Brasil.
            </p>
            <div className="flex space-x-4">
              <a 
                href="https://www.facebook.com/share/US3LSAmhbBGdjxdK/?mibextid=qi2Omg" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-400 hover:text-uai-teal transition-colors"
              >
                <Facebook className="w-6 h-6" />
              </a>
              <a 
                href="https://www.instagram.com/grupouaisat/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-400 hover:text-uai-teal transition-colors"
              >
                <Instagram className="w-6 h-6" />
              </a>
              <a 
                href="https://www.youtube.com/@GrupoUaiSat" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-400 hover:text-uai-teal transition-colors"
              >
                <Youtube className="w-6 h-6" />
              </a>
            </div>
          </div>
          
          {/* Column 2 - Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Links rápidos</h3>
            <ul className="space-y-2">
              <li>
                <a 
                  href="https://rastreamento.correios.com.br/app/index.php"
                  target="_blank"
                  rel="noopener noreferrer" 
                  className="text-gray-400 hover:text-uai-teal transition-colors flex items-center"
                >
                  <ArrowRight className="w-4 h-4 mr-2 text-uai-teal" />
                  <span>Rastrear meu pedido</span>
                </a>
              </li>
              <li>
                <Link to="/vendedores" className="text-gray-400 hover:text-uai-teal transition-colors flex items-center">
                  <ArrowRight className="w-4 h-4 mr-2 text-uai-teal" />
                  <span>Encontrar vendedor</span>
                </Link>
              </li>
              <li>
                <Link to="/seja-revendedor" className="text-gray-400 hover:text-uai-teal transition-colors flex items-center">
                  <ArrowRight className="w-4 h-4 mr-2 text-uai-teal" />
                  <span>Seja um revendedor</span>
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-gray-400 hover:text-uai-teal transition-colors flex items-center">
                  <ArrowRight className="w-4 h-4 mr-2 text-uai-teal" />
                  <span>FAQ</span>
                </Link>
              </li>
              <li>
                <Link to="/ativar-esim" className="text-gray-400 hover:text-uai-teal transition-colors flex items-center">
                  <ArrowRight className="w-4 h-4 mr-2 text-uai-teal" />
                  <span>Como ativar eSIM</span>
                </Link>
              </li>
              <li>
                <Link to="/politica-de-privacidade" className="text-gray-400 hover:text-uai-teal transition-colors flex items-center">
                  <ArrowRight className="w-4 h-4 mr-2 text-uai-teal" />
                  <span>Política de privacidade</span>
                </Link>
              </li>
              <li>
                <Link to="/termos-e-condicoes" className="text-gray-400 hover:text-uai-teal transition-colors flex items-center">
                  <ArrowRight className="w-4 h-4 mr-2 text-uai-teal" />
                  <span>Termos de uso</span>
                </Link>
              </li>
            </ul>
          </div>
          
          {/* Column 3 - Services */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Serviços</h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <Smartphone className="w-4 h-4 text-uai-teal mr-2" />
                <a href="/#planos" className="text-gray-400 hover:text-uai-teal transition-colors">Plano Controle</a>
              </li>
              <li className="flex items-center">
                <CreditCard className="w-4 h-4 text-uai-teal mr-2" />
                <a 
                  href="https://equipe.uaimovel.com.br/recarga/"
                  target="_blank"
                  rel="noopener noreferrer" 
                  className="text-gray-400 hover:text-uai-teal transition-colors"
                >
                  Recarga online
                </a>
              </li>
              <li className="flex items-center">
                <MapPinIcon className="w-4 h-4 text-uai-teal mr-2" />
                <Link to="/lojas" className="text-gray-400 hover:text-uai-teal transition-colors">Encontre uma loja</Link>
              </li>
              <li className="flex items-center">
                <Users className="w-4 h-4 text-uai-teal mr-2" />
                <Link to="/vendedores" className="text-gray-400 hover:text-uai-teal transition-colors">Encontrar vendedor</Link>
              </li>
              <li className="flex items-center">
                <Store className="w-4 h-4 text-uai-teal mr-2" />
                <Link to="/seja-revendedor" className="text-gray-400 hover:text-uai-teal transition-colors">Seja revendedor</Link>
              </li>
            </ul>
          </div>
          
          {/* Column 4 - Contact */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contato</h3>
            <ul className="space-y-3">
              <li className="flex items-start">
                <Phone className="w-5 h-5 text-uai-teal mr-2 flex-shrink-0 mt-0.5" />
                <div>
                  <p className="text-gray-400">Atendimento</p>
                  <p className="text-white">*288 ou 10543</p>
                </div>
              </li>
              <li className="flex items-start">
                <Mail className="w-5 h-5 text-uai-teal mr-2 flex-shrink-0 mt-0.5" />
                <div>
                  <p className="text-gray-400">E-mail</p>
                  <p className="text-white">contato@uaimovel.com.br</p>
                </div>
              </li>
              <li className="flex items-start">
                <MapPin className="w-5 h-5 text-uai-teal mr-2 flex-shrink-0 mt-0.5" />
                <div>
                  <p className="text-gray-400">Central de Vendas</p>
                  <p className="text-white">Av. Paulista 1636<br />Bela Vista, São Paulo-SP<br />01310-200</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-800 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-400 mb-4 md:mb-0">&copy; {new Date().getFullYear()} UaiSat Telecomunicações. Todos os direitos reservados.</p>
          <div className="flex space-x-4">
            <Link to="/termos-e-condicoes" className="text-gray-400 hover:text-uai-teal transition-colors text-sm">Termos</Link>
            <Link to="/politica-de-privacidade" className="text-gray-400 hover:text-uai-teal transition-colors text-sm">Privacidade</Link>
            <Link to="/politica-de-privacidade#cookies" className="text-gray-400 hover:text-uai-teal transition-colors text-sm">Cookies</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;