import React from 'react';
import { Shield, AlertCircle, Clock, CreditCard, Smartphone, Wifi, Database, HelpCircle } from 'lucide-react';

const Terms: React.FC = () => {
  return (
    <div className="py-16 px-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-8">Termo de Adesão - Planos e Benefícios</h1>
        
        <div className="prose prose-lg max-w-none">
          <p className="text-xl text-gray-600 mb-8">
            O plano/benefício de serviço pré-pago é realizado em conjunto com a prestadora de serviços Telecom – Uai Sat, para toda pessoa física ou jurídica, doravante denominado Consumidor. Ao comprar um chip e carregar-o com um dos valores de planos/benefícios disponíveis, o consumidor está aderindo automaticamente ao plano/benefício correspondente ao plano/benefício de serviço comercializado, conforme previsto no presente Termo de Adesão e divulgado no nosso site.
          </p>

          {/* Ativação */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Smartphone className="w-6 h-6 text-uai-teal mr-2" />
              1. Ativação do plano/benefício de serviço
            </h2>
            <p className="mb-4">
              A ativação do plano/benefício do serviço é feita automaticamente após a aquisição do plano/benefício pelo consumidor.
            </p>
            <p>
              Para aderir a um plano/benefício de serviço, o consumidor deve ter adquirido e ativado previamente uma linha Uai Sat e escolher um dos valores de recarga disponíveis.
            </p>
          </section>

          {/* Planos */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Database className="w-6 h-6 text-uai-teal mr-2" />
              2. Planos/benefícios de serviços
            </h2>
            <p className="mb-4">
              Os valores dos planos/benefícios de serviços estão disponíveis no site da Uai Sat.
            </p>
            <p className="mb-4">
              Adicionalmente ao valor do plano/benefício de serviço poderá ocorrer a cobrança pela aquisição do chip (físico ou virtual).
            </p>
            <h3 className="text-xl font-bold mt-6 mb-4">2.1 Bônus de portabilidade e recarga programada</h3>
            <p className="mb-4">
              Os planos/benefícios podem ter bônus de portabilidade e/ou recarga programada.
            </p>
            <p className="mb-4">
              O primeiro bônus de portabilidade será concedido em até 7 dias úteis após a conclusão, com sucesso, do processo de portabilidade.
            </p>
            <p className="mb-4">
              Nas próximas aquisições de planos/benefícios os bônus de portabilidade e recarga programada são disponibilizados automaticamente acompanhando a vigência do plano, ou seja, se o consumidor adquirir o plano sendo um consumidor portado ou com recarga programada, ele não receberá essa bonificação apenas no seu primeiro mês de uso do chip, mas sim durante todo o período em que o consumidor manter seu plano ativo.
            </p>
            <p>
              O primeiro bônus de recarga programada será concedido a partir do 2º mês após aquisição do plano/benefício com cartão de crédito e marcação da recarga programada, com sucesso. No caso dos planos trimestrais, semestrais e anuais os bônus de recarga programada dos planos/benefícios serão atribuídos mensalmente nas renovações no período contratado (trimestral, semestral e/ou anual).
            </p>
          </section>

          {/* Ligações */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Smartphone className="w-6 h-6 text-uai-teal mr-2" />
              3. Ligações
            </h2>
            <p className="mb-4">
              Todas as chamadas para os serviços públicos de emergência são gratuitas. As chamadas para os demais serviços de utilidade pública, por sua vez, poderão ser tarifadas pelo valor de uma chamada local, embora a prestação do serviço propriamente dito seja gratuita.
            </p>
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-bold mb-2">3.1 Chamadas ilimitadas</h3>
                <p>
                  As chamadas ilimitadas são para ligações locais e de longa distância nacionais com código de seleção de prestadora 41 (CSP 41) para números de telefone fixos ou móveis de qualquer prestadora dentro do território nacional, desde que não se caracterizem pelo uso fraudulento.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">3.2 Uso do benefício</h3>
                <p>
                  Para plano/benefício que contenha ligações ilimitadas, o consumidor poderá usufruir do benefício dentro do seu prazo de vigência, desde que não haja uso fraudulento e não esteja enquadrado em uso indevido, conforme descrito no item 8 do presente Termo de Adesão.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">3.3 Chamadas não incluídas no benefício da oferta</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Ligações de Longa Distância Internacional, Roaming Internacional de Voz, Dados e SMS com qualquer código de prestadora, inclusive o código 41 da TIM;</li>
                  <li>Ligações de Longa Distância Nacional sem o código 41;</li>
                  <li>Ligações para códigos não geográficos como 0300, 0500 e 0900;</li>
                  <li>Ligações para números especiais com três ou quatro dígitos, com exceção das chamadas para os Serviços de Utilidade Pública e de Emergência (SUP) listados na regulamentação da ANATEL;</li>
                  <li>Utilização de qualquer serviço fora da Rede Surf Telecom;</li>
                  <li>Serviços adicionais por ligação como downloads de sons e jogos, votação e concursos, portais de voz etc., possuem variáveis de custos, de acordo com o provedor da campanha ou serviço em questão.</li>
                </ul>
              </div>
            </div>
          </section>

          {/* SMS */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Smartphone className="w-6 h-6 text-uai-teal mr-2" />
              4. SMS
            </h2>
            <p>
              O serviço de mensagens curtas (SMS - Short Message Service em inglês) é apenas para números móveis nacionais, de acordo com o plano/benefício contratado no plano/benefício de serviço.
            </p>
          </section>

          {/* Pagamento e Renovação */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <CreditCard className="w-6 h-6 text-uai-teal mr-2" />
              5. Pagamento e Renovação
            </h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-bold mb-2">5.1 Renovações mensais</h3>
                <p>
                  O consumidor poderá optar por fazer renovações mensais mediante aquisição de planos/benefícios que melhor lhe convier dentro dos disponíveis em qualquer canal de atendimento da Uai Sat, presencial ou remoto num dos valores disponíveis.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">5.1.1 Atendimento presencial</h3>
                <p>
                  Considera-se atendimento presencial, todo atendimento realizado nos estabelecimentos da Uai Sat e nos pontos de atendimento associados à sua marca.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">5.1.2 Atendimento remoto</h3>
                <p>
                  Considera-se atendimento remoto aquele realizado por meio de Centro de Atendimento Telefônico, do Atendimento por Internet, bem como por qualquer outro meio disponibilizado ou utilizado pela prestadora em conjunto com a Uai Sat para interação remota com o consumidor.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">5.2 Formas de pagamento</h3>
                <p>
                  A renovação mensal poderá ser feita mediante pagamento em dinheiro nos pontos de atendimento presencial ou por outros meios disponíveis como cartão de crédito, cartão de débito, dentro outros.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">5.3 Vencimento</h3>
                <p>
                  O consumidor que não realizar uma recarga até a data de vencimento do plano/benefício vigente, terá seu plano/benefício remanescente expirado.
                </p>
              </div>
            </div>
          </section>

          {/* Mecânica dos planos */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Wifi className="w-6 h-6 text-uai-teal mr-2" />
              6. Mecânica dos planos
            </h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-bold mb-4">6.1 Internet sem Cortes</h3>
                <p>
                  Durante a validade do plano/benefício contratado, mesmo após o consumo de 100% do plano/benefício de dados, o acesso à internet não será cortado e o consumidor continuará navegando em velocidade reduzida (32 Kbps) até o final do prazo de validade do plano/benefício.
                </p>
              </div>

              <div>
                <h3 className="text-xl font-bold mb-4">6.2 Dados de Expiração do plano/benefício</h3>
                <p className="mb-4">
                  O consumidor que adquirir um plano/benefício terá os dados de expiração garantidos pelo período do plano/benefício adquirido.
                </p>
                <p>
                  Os planos/benefícios trimestrais, semestrais e anuais têm sua renovação agendada mensalmente, garantida durante todo o período contratado. Nesse plano/benefício o consumidor adquire 3, 6 ou 12 recargas de 1 mês de validade, com recorrência programada mensalmente.
                </p>
              </div>

              <div>
                <h3 className="text-xl font-bold mb-4">6.3 Acúmulo de benefícios</h3>
                <p className="mb-4">
                  Quando realizada a compra de um plano/benefício antes da data de vencimento do plano/benefício atual, além do benefício recém-adquirido, o consumidor poderá acumular saldos remanescentes, conforme a regra abaixo:
                </p>
                <p className="font-bold mb-4">
                  Limite de Acúmulo de Benefícios = Benefício da Recarga Adquirido + Recebidos nos últimos 20 ou 30 dias.
                </p>

                <div className="space-y-6">
                  <div>
                    <h4 className="text-lg font-bold mb-2">EXEMPLO DE ACÚMULO DE MINUTOS:</h4>
                    <img 
                      src="https://privacidade.operadora.app.br/assets/img2-0bb4b396.png"
                      alt="Exemplo de acúmulo de minutos"
                      className="rounded-lg shadow-lg"
                    />
                  </div>

                  <div>
                    <h4 className="text-lg font-bold mb-2">EXEMPLO DE ACÚMULO DE SMS:</h4>
                    <img 
                      src="https://privacidade.operadora.app.br/assets/img3-f9368d88.png"
                      alt="Exemplo de acúmulo de SMS"
                      className="rounded-lg shadow-lg"
                    />
                  </div>

                  <div>
                    <h4 className="text-lg font-bold mb-2">EXEMPLO DE ACÚMULO DE GIGA:</h4>
                    <img 
                      src="https://privacidade.operadora.app.br/assets/img4-7bd4805b.png"
                      alt="Exemplo de acúmulo de GIGA"
                      className="rounded-lg shadow-lg"
                    />
                  </div>
                </div>

                <p className="mt-6 text-sm text-gray-600">
                  *Recargas efetuadas antes da data de validade do seu plano (30 ou 20 dias).
                </p>
                <p className="mt-4">
                  Caso a nova recarga seja feita após o término do período de validade do plano/benefício, não haverá acúmulo de benefício referente ao plano/benefício anterior, pois os saldos remanescentes já foram expirados. Em caso nenhum, poderão ser acumulados benefícios de voz superiores a 2.000 minutos, benefícios de SMS superiores a 300 e benefícios de dados superiores a 500 GB.
                </p>
              </div>

              <div>
                <h3 className="text-xl font-bold mb-4">6.4 Acesso a aplicativos e sites gratuitos</h3>
                <p>
                  Caso o plano/benefício contratado ofereça acesso promocionalmente gratuito a aplicativos e sites, como os de redes sociais, esses acessos não serão descontados do plano/benefício desde que as utilizações não sejam para chamadas de vídeo, downloads de vídeos e backups da própria ferramenta.
                </p>
              </div>

              <div>
                <h3 className="text-xl font-bold mb-4">6.5 WhatsApp grátis</h3>
                <p className="mb-4">
                  Nos casos dos planos/benefícios com WhatsApp gratuito, a gratuidade aplica-se para o envio/recebimento de mensagens de texto, arquivos de foto e arquivos de áudio.
                </p>
                <p className="mb-4">
                  As ligações (chamadas) de voz ou vídeo realizadas e/ou recebidas através do WhatsApp são cobradas (deduzidas do pacote de dados) normalmente.
                </p>
                <p>
                  Da mesma forma, qualquer utilização de internet fora do aplicativo, mesmo que originada de um link enviado via aplicativo, irá gerar desconto do pacote de internet que o consumidor tiver ativo.
                </p>
              </div>
            </div>
          </section>

          {/* Rede e Cobertura */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Wifi className="w-6 h-6 text-uai-teal mr-2" />
              7. Rede e Cobertura
            </h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-bold mb-4">7.1 Roaming Nacional</h3>
                <p className="mb-4">
                  Dentro da área de cobertura de sua operadora de serviço, o Roaming é gratuito para o consumidor. Não haverá cobrança adicional para o encaminhamento de chamadas de longa distância em todo o território nacional.
                </p>
                <p>
                  Não haverá cobrança de taxa de deslocamento para as chamadas recebidas fora de sua localidade quando em território nacional.
                </p>
              </div>

              <div>
                <h3 className="text-xl font-bold mb-4">7.2 Velocidades de navegação na internet</h3>
                <p>
                  Desde que a qualidade de sinal seja garantida onde o aparelho se encontra, a velocidade de referência padrão na rede 3G é de até 1 MBPS para download e de até 100 KBPS para upload, enquanto na rede 4G é de até 5 MBPS para download e de até 500 KBPS para upload.
                </p>
              </div>
            </div>
          </section>

          {/* Usos não autorizados */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <AlertCircle className="w-6 h-6 text-uai-teal mr-2" />
              8. Usos não autorizados no plano/benefício de serviço
            </h2>
            <p className="mb-4">
              O consumidor será passível de bloqueio e cancelamento de sua adesão a este termo, quando for identificado o uso indevido do plano/benefício enquadrado em quaisquer dos itens abaixo:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Comercialização de minutos/serviços ou utilização de SMS (mensagens) com finalidade comercial, destinada à obtenção de lucro por parte do consumidor;</li>
              <li>Envio de SMS (mensagens) através de máquinas, computadores ou outro dispositivo que não seja o aparelho celular do consumidor;</li>
              <li>Envio de SMS (mensagens) indesejadas classificadas como SPAM;</li>
              <li>Realização de chamadas através de máquinas, computadores ou outros dispositivos que não sejam o aparelho celular utilizado pelo consumidor;</li>
              <li>Realização de chamadas indesejáveis como SPAM;</li>
              <li>Utilização de equipamentos como GSM Box, Black Box e equipamentos similares;</li>
              <li>Desbalanceamento do tráfego santo/entrante, contendo volume de chamadas originadas acima de três vezes o de chamadas recebidas;</li>
              <li>Utilização do plano/benefício para realização de conferências;</li>
              <li>Utilização do plano/benefício para serviços de salas de conversação, tele amizade, telesexo e similares.</li>
            </ul>
          </section>

          {/* Cancelamento */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <HelpCircle className="w-6 h-6 text-uai-teal mr-2" />
              9. Cancelamento
            </h2>
            <p>
              O consumidor pode realizar o cancelamento da oferta entrando em contato via chat com nossa equipe de atendimento direto no aplicativo.
            </p>
          </section>

          {/* Renovação */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Clock className="w-6 h-6 text-uai-teal mr-2" />
              10. Renovação da Adesão ao plano/benefício de serviço
            </h2>
            <div className="space-y-4">
              <p>
                10.1 A renovação/contratação do plano/benefício ocorre sempre que o consumidor da operadora de serviço efetue uma recarga do plano/benefício correspondente.
              </p>
              <p>
                10.2 O consumidor tem o direito de escolher o plano/benefício que melhor lhe convier com base nas recargas que estão disponíveis nos canais de recarga da prestada.
              </p>
            </div>
          </section>

          {/* Last Update */}
          <div className="text-sm text-gray-600 mt-12">
            <p>Última atualização: Março de 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;