import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from './supabase';
import { useAuth } from './auth';

interface RechargeHistory {
  id: string;
  phone: string;
  amount: number;
  plan_id: string;
  plan_data: string;
  plan_validity: string;
  status: 'pending' | 'success' | 'failed';
  payment_url?: string;
  created_at: string;
}

interface RechargeStats {
  totalRecharges: number;
  totalSpent: number;
  lastRecharge: string | null;
  mostUsedNumber: string | null;
}

interface RechargeStore {
  recentNumbers: string[];
  history: RechargeHistory[];
  stats: RechargeStats;
  addRecentNumber: (number: string) => void;
  addHistoryItem: (item: Omit<RechargeHistory, 'id' | 'created_at'>) => Promise<void>;
  loadHistory: () => Promise<void>;
  getStats: () => RechargeStats;
  subscribeToChanges: () => () => void;
}

export const useRechargeStore = create<RechargeStore>()(
  persist(
    (set, get) => ({
      recentNumbers: [],
      history: [],
      stats: {
        totalRecharges: 0,
        totalSpent: 0,
        lastRecharge: null,
        mostUsedNumber: null
      },
      addRecentNumber: (number) =>
        set((state) => ({
          recentNumbers: [
            number,
            ...state.recentNumbers.filter((n) => n !== number)
          ].slice(0, 5) // Keep only last 5 numbers
        })),
      addHistoryItem: async (item) => {
        const { user } = useAuth.getState();
        if (!user) return;

        try {
          const { data, error } = await supabase
            .from('recharge_history')
            .insert([{
              user_id: user.cpf,
              ...item
            }])
            .select()
            .single();

          if (error) throw error;

          // Update local state
          set((state) => {
            const newHistory = [data, ...state.history];
            return {
              history: newHistory,
              stats: calculateStats(newHistory)
            };
          });
        } catch (error) {
          console.error('Error adding recharge history:', error);
          throw error;
        }
      },
      loadHistory: async () => {
        const { user } = useAuth.getState();
        if (!user) return;

        try {
          const { data, error } = await supabase
            .from('recharge_history')
            .select('*')
            .eq('user_id', user.cpf)
            .order('created_at', { ascending: false });

          if (error) throw error;

          set({
            history: data,
            stats: calculateStats(data)
          });
        } catch (error) {
          console.error('Error loading recharge history:', error);
          throw error;
        }
      },
      getStats: () => {
        return get().stats;
      },
      subscribeToChanges: () => {
        const { user } = useAuth.getState();
        if (!user) return () => {};

        // Subscribe to changes
        const subscription = supabase
          .channel('recharge_history_changes')
          .on(
            'postgres_changes',
            {
              event: '*',
              schema: 'public',
              table: 'recharge_history',
              filter: `user_id=eq.${user.cpf}`
            },
            async () => {
              // Reload history when changes occur
              await get().loadHistory();
            }
          )
          .subscribe();

        // Return unsubscribe function
        return () => {
          subscription.unsubscribe();
        };
      }
    }),
    {
      name: 'uaisat-recharge-store',
      // Only persist recent numbers, not history
      partialize: (state) => ({ recentNumbers: state.recentNumbers })
    }
  )
);

// Helper function to calculate stats
function calculateStats(history: RechargeHistory[]): RechargeStats {
  if (history.length === 0) {
    return {
      totalRecharges: 0,
      totalSpent: 0,
      lastRecharge: null,
      mostUsedNumber: null
    };
  }

  const numberCounts = history.reduce((acc, item) => {
    acc[item.phone] = (acc[item.phone] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  return {
    totalRecharges: history.length,
    totalSpent: history.reduce((sum, item) => sum + item.amount, 0),
    lastRecharge: history[0].created_at,
    mostUsedNumber: Object.entries(numberCounts)
      .sort(([,a], [,b]) => b - a)[0][0]
  };
}