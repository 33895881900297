export interface Plan {
  id: string;
  name: string;
  type: 'controle' | 'prepago';
  price: number;
  data: string;
  validity?: string;
  social: string;
  calls: string;
  benefits: string[];
  active: boolean;
}

export interface Banner {
  id: string;
  title: string;
  description: string;
  cta: string;
  image_url: string;
  color: string;
  display_order: number;
  active: boolean;
  link: string;
}

export const plans: Plan[] = [
  {
    id: '1',
    name: 'UaiSmart',
    type: 'controle',
    price: 29.90,
    data: '6GB',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '4GB Principal',
      '2GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '2',
    name: 'UaiTurbo',
    type: 'controle',
    price: 49.99,
    data: '15GB',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '11GB Principal',
      '4GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '3',
    name: 'UaiExtreme',
    type: 'controle',
    price: 79.90,
    data: '35GB',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '25GB Principal',
      '10GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '4',
    name: 'Recarga',
    type: 'prepago',
    price: 30.00,
    data: '6GB',
    validity: '30 dias',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '4GB Principal',
      '2GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '5',
    name: 'Recarga',
    type: 'prepago',
    price: 50.00,
    data: '15GB',
    validity: '30 dias',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '11GB Principal',
      '4GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '6',
    name: 'Recarga',
    type: 'prepago',
    price: 90.00,
    data: '35GB',
    validity: '30 dias',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '25GB Principal',
      '10GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  }
];

export const banners: Banner[] = [
  {
    id: '1',
    title: 'Planos Controle',
    description: 'A partir de R$29,90! WhatsApp ilimitado + Ligações grátis para qualquer operadora',
    cta: 'Conheça os Planos',
    image_url: 'https://cloud.uaisat.app.br/09/1.png',
    color: '#00a9a7',
    display_order: 1,
    active: true,
    link: '/#planos'
  },
  {
    id: '2',
    title: 'UaiExtreme 35GB',
    description: 'O plano perfeito para você que não vive sem internet! 35GB de internet + WhatsApp e Waze grátis',
    cta: 'Assine Agora',
    image_url: 'https://cloud.uaisat.app.br/09/foto-planos.png',
    color: '#6366f1',
    display_order: 2,
    active: true,
    link: 'https://equipe.uaimovel.com.br/solicitar-chip/'
  },
  {
    id: '3',
    title: 'Frete Grátis',
    description: 'Peça seu chip físico ou virtual (eSIM) com frete grátis para todo o Brasil!',
    cta: 'Peça seu Chip',
    image_url: 'https://cloud.uaisat.app.br/09/01.png',
    color: '#10b981',
    display_order: 3,
    active: true,
    link: 'https://equipe.uaimovel.com.br/solicitar-chip/'
  }
];