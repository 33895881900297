import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRechargeStore } from '../../lib/store';
import { processRecharge } from '../../lib/api';
import { IMaskInput } from 'react-imask';
import { Send, Loader2 } from 'lucide-react';
import toast from 'react-hot-toast';

const PLANS = [
  { id: '428', value: 30, data: '6GB', validity: '30 dias' },
  { id: '429', value: 40, data: '10GB', validity: '30 dias' },
  { id: '430', value: 50, data: '15GB', validity: '30 dias' },
  { id: '431', value: 60, data: '20GB', validity: '30 dias' },
  { id: '432', value: 90, data: '35GB', validity: '30 dias' }
];

const Recharge: React.FC = () => {
  const navigate = useNavigate();
  const { addHistoryItem } = useRechargeStore();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    telefone: '',
    id_plano: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!formData.telefone || !formData.id_plano) {
        throw new Error('Preencha todos os campos');
      }

      const response = await processRecharge(formData.telefone, formData.id_plano);
      
      if (response.link_checkout) {
        // Add to history
        const plan = PLANS.find(p => p.id === formData.id_plano);
        if (plan) {
          await addHistoryItem({
            phone: formData.telefone,
            amount: plan.value,
            plan_id: plan.id,
            plan_data: plan.data,
            plan_validity: plan.validity,
            status: 'pending',
            payment_url: response.link_checkout
          });
        }

        // Show success message
        toast.success('Redirecionando para o pagamento...');
        
        // Open payment URL in new tab
        window.open(response.link_checkout, '_blank', 'noopener,noreferrer');
        
        // Navigate to history page
        navigate('/app/history');
      } else {
        throw new Error('URL de pagamento não encontrada');
      }
    } catch (error) {
      console.error('Error processing recharge:', error);
      toast.error(error instanceof Error ? error.message : 'Erro ao processar recarga');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Nova Recarga</h1>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Número do telefone
          </label>
          <IMaskInput
            mask="(00) 00000-0000"
            value={formData.telefone}
            unmask={true}
            onAccept={(value) => setFormData(prev => ({ ...prev, telefone: value }))}
            placeholder="(00) 00000-0000"
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-4">
            Selecione o valor da recarga
          </label>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {PLANS.map((plan) => (
              <button
                key={plan.id}
                type="button"
                onClick={() => setFormData(prev => ({ ...prev, id_plano: plan.id }))}
                className={`p-6 rounded-xl border-2 flex flex-col items-center justify-center gap-2 transition-all duration-300 hover:shadow-lg ${
                  formData.id_plano === plan.id
                    ? 'border-uai-teal bg-uai-light text-uai-teal'
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <div className="text-center">
                  <span className="text-2xl font-bold">R$ {plan.value}</span>
                  <div className="text-sm text-gray-600 mt-1">
                    <p className="font-medium">{plan.data} de Internet</p>
                    <p>Validade: {plan.validity}</p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          disabled={loading || !formData.telefone || !formData.id_plano}
          className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 flex items-center justify-center group disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
              Processando...
            </>
          ) : (
            <>
              <Send className="w-5 h-5 mr-2 transform group-hover:translate-x-1 transition-transform duration-300" />
              Fazer Recarga
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default Recharge;