import React, { useState } from 'react';
import { Check, Zap, Shield, ArrowRight, Loader2 } from 'lucide-react';
import type { Plan } from '../lib/data';
import { processRecharge } from '../lib/api';
import toast from 'react-hot-toast';
import { IMaskInput } from 'react-imask';
import { Link } from 'react-router-dom';

interface PlanCardProps {
  plan: Plan;
  type: 'controle' | 'prepago';
}

const PLAN_ID_MAP: Record<number, string> = {
  30: '428',
  40: '429',
  50: '430',
  60: '431',
  90: '432'
};

const PlanCard: React.FC<PlanCardProps> = ({ plan, type }) => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const isPrePago = type === 'prepago';

  const handleRecharge = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const planId = PLAN_ID_MAP[plan.price];
      if (!planId) {
        throw new Error('Plano inválido');
      }

      const response = await processRecharge(phone, planId);
      
      if (response.link_checkout) {
        // Show success message
        toast.success('Redirecionando para o pagamento...');
        
        // Open payment URL in new tab
        window.open(response.link_checkout, '_blank', 'noopener,noreferrer');
        
        // Reset form
        setPhone('');
        setShowPhoneInput(false);
      } else {
        throw new Error('URL de pagamento não encontrada');
      }
    } catch (error) {
      console.error('Error processing recharge:', error);
      toast.error(error instanceof Error ? error.message : 'Erro ao processar recarga');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-card overflow-hidden hover:shadow-2xl transition-all duration-500 transform hover:-translate-y-2 group">
      <div className="bg-gradient-to-r from-uai-teal to-uai-dark text-white p-6">
        <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
        <div className="flex items-baseline">
          <span className="text-lg opacity-80">R$</span>
          <span className="text-5xl font-bold mx-1">{plan.price.toFixed(2).split('.')[0]}</span>
          <span className="text-xl opacity-80">,{plan.price.toFixed(2).split('.')[1]}</span>
          <span className="text-sm opacity-80 ml-1">/{isPrePago ? 'recarga' : 'mês'}</span>
        </div>
        {isPrePago && 'validity' in plan && (
          <p className="text-sm mt-2 opacity-90">Validade: {plan.validity}</p>
        )}
      </div>
      
      <div className="p-6">
        <div className="space-y-6">
          <div className="flex items-center bg-uai-light p-4 rounded-xl">
            <Zap className="w-6 h-6 text-uai-teal mr-3" />
            <div>
              <p className="font-semibold text-lg">{plan.data}</p>
              <p className="text-sm text-gray-600">de internet 4G/5G</p>
            </div>
          </div>
          
          <div className="space-y-3">
            <div className="flex items-center">
              <div className="w-6 h-6 rounded-full bg-green-100 flex items-center justify-center mr-3">
                <Check className="w-4 h-4 text-green-600" />
              </div>
              <span>{plan.social}</span>
            </div>
            
            <div className="flex items-center">
              <div className="w-6 h-6 rounded-full bg-green-100 flex items-center justify-center mr-3">
                <Check className="w-4 h-4 text-green-600" />
              </div>
              <span>{plan.calls}</span>
            </div>
          </div>
          
          <div className="border-t border-gray-100 pt-4">
            <div className="flex items-center mb-3">
              <Shield className="w-5 h-5 text-uai-teal mr-2" />
              <p className="font-semibold">Benefícios inclusos:</p>
            </div>
            <ul className="space-y-2">
              {plan.benefits.map((benefit, index) => (
                <li key={index} className="flex items-start">
                  <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center mr-2 mt-0.5 flex-shrink-0">
                    <Check className="w-3 h-3 text-green-600" />
                  </div>
                  <span className="text-sm text-gray-700">{benefit}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
        {isPrePago ? (
          showPhoneInput ? (
            <form onSubmit={handleRecharge} className="mt-6 space-y-4">
              <div>
                <IMaskInput
                  mask="(00) 00000-0000"
                  value={phone}
                  unmask={true}
                  onAccept={(value) => setPhone(value)}
                  placeholder="(00) 00000-0000"
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                  required
                />
              </div>
              <div className="flex gap-2">
                <button
                  type="button"
                  onClick={() => setShowPhoneInput(false)}
                  className="flex-1 px-4 py-3 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  disabled={loading || !phone}
                  className="flex-1 bg-uai-teal hover:bg-uai-dark text-white font-bold py-3 px-4 rounded-lg transition-all duration-300 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                    <Loader2 className="w-5 h-5 animate-spin" />
                  ) : (
                    'Recarregar'
                  )}
                </button>
              </div>
            </form>
          ) : (
            <button
              onClick={() => setShowPhoneInput(true)}
              className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-6 rounded-xl mt-6 transition-all duration-300 flex items-center justify-center group"
            >
              <span className="mr-2">Recarregar agora</span>
              <ArrowRight className="w-5 h-5 transform transition-transform duration-300 group-hover:translate-x-1" />
            </button>
          )
        ) : (
          <Link
            to="/solicitar-chip"
            className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-6 rounded-xl mt-6 transition-all duration-300 flex items-center justify-center group"
          >
            <span className="mr-2">Pedir meu chip</span>
            <ArrowRight className="w-5 h-5 transform transition-transform duration-300 group-hover:translate-x-1" />
          </Link>
        )}
      </div>
    </div>
  );
};

export default PlanCard;