import React, { useState } from 'react';
import { Send, Loader2, Upload } from 'lucide-react';
import { sendEmail, TEMPLATES } from '../../lib/emailjs';
import toast from 'react-hot-toast';

interface CareerFormProps {
  onSuccess?: () => void;
}

const CareerForm: React.FC<CareerFormProps> = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    position: '',
    experience: '',
    education: '',
    linkedin: '',
    message: ''
  });

  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      await sendEmail({
        templateId: TEMPLATES.CAREER,
        templateParams: {
          from_name: formData.name,
          from_email: formData.email,
          phone: formData.phone,
          city: formData.city,
          position: formData.position,
          experience: formData.experience,
          education: formData.education,
          linkedin: formData.linkedin,
          message: formData.message
        }
      });

      toast.success('Currículo enviado com sucesso!');
      setFormData({
        name: '',
        email: '',
        phone: '',
        city: '',
        position: '',
        experience: '',
        education: '',
        linkedin: '',
        message: ''
      });
      onSuccess?.();
    } catch (error) {
      console.error('Error sending career form:', error);
      toast.error('Erro ao enviar currículo. Tente novamente.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-white p-8 rounded-2xl shadow-xl relative overflow-hidden">
      {/* Background decoration */}
      <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-uai-teal/10 to-transparent rounded-full mix-blend-multiply filter blur-xl animate-blob" />
      <div className="absolute -bottom-32 -left-32 w-64 h-64 bg-gradient-to-br from-uai-dark/10 to-transparent rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000" />
      
      <div className="relative">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold mb-2">Faça parte do nosso time</h2>
          <p className="text-gray-600">
            Preencha o formulário abaixo e junte-se a uma das operadoras que mais crescem no Brasil
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Personal Information */}
          <div className="bg-gray-50/80 backdrop-blur-sm p-6 rounded-xl space-y-6 border border-gray-100">
            <h3 className="font-semibold text-lg flex items-center">
              <span className="w-8 h-8 rounded-lg bg-uai-light flex items-center justify-center mr-3">
                <Upload className="w-4 h-4 text-uai-teal" />
              </span>
              Informações Pessoais
            </h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
                  Nome completo
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-uai-teal focus:border-transparent bg-white"
                  required
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-uai-teal focus:border-transparent bg-white"
                  required
                />
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-2">
                  Telefone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-uai-teal focus:border-transparent bg-white"
                  required
                />
              </div>

              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-2">
                  Cidade
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-uai-teal focus:border-transparent bg-white"
                  required
                />
              </div>
            </div>
          </div>

          {/* Professional Information */}
          <div className="bg-gray-50/80 backdrop-blur-sm p-6 rounded-xl space-y-6 border border-gray-100">
            <h3 className="font-semibold text-lg flex items-center">
              <span className="w-8 h-8 rounded-lg bg-uai-light flex items-center justify-center mr-3">
                <Upload className="w-4 h-4 text-uai-teal" />
              </span>
              Informações Profissionais
            </h3>

            <div>
              <label htmlFor="position" className="block text-sm font-medium text-gray-700 mb-2">
                Área de interesse
              </label>
              <select
                id="position"
                name="position"
                value={formData.position}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-uai-teal focus:border-transparent bg-white"
                required
              >
                <option value="">Selecione uma área</option>
                <option value="comercial">Comercial</option>
                <option value="atendimento">Atendimento ao Cliente</option>
                <option value="tecnico">Suporte Técnico</option>
                <option value="marketing">Marketing</option>
                <option value="ti">Tecnologia da Informação</option>
                <option value="administrativo">Administrativo</option>
                <option value="financeiro">Financeiro</option>
                <option value="rh">Recursos Humanos</option>
              </select>
            </div>

            <div>
              <label htmlFor="experience" className="block text-sm font-medium text-gray-700 mb-2">
                Experiência profissional
              </label>
              <textarea
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                rows={4}
                placeholder="Descreva suas experiências profissionais anteriores, incluindo cargo, empresa e período"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-uai-teal focus:border-transparent bg-white"
                required
              ></textarea>
            </div>

            <div>
              <label htmlFor="education" className="block text-sm font-medium text-gray-700 mb-2">
                Formação acadêmica
              </label>
              <textarea
                id="education"
                name="education"
                value={formData.education}
                onChange={handleChange}
                rows={3}
                placeholder="Descreva sua formação acadêmica, incluindo curso, instituição e ano de conclusão"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-uai-teal focus:border-transparent bg-white"
                required
              ></textarea>
            </div>

            <div>
              <label htmlFor="linkedin" className="block text-sm font-medium text-gray-700 mb-2">
                LinkedIn (opcional)
              </label>
              <input
                type="url"
                id="linkedin"
                name="linkedin"
                value={formData.linkedin}
                onChange={handleChange}
                placeholder="https://linkedin.com/in/seu-perfil"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-uai-teal focus:border-transparent bg-white"
              />
            </div>
          </div>

          {/* Additional Information */}
          <div className="bg-gray-50/80 backdrop-blur-sm p-6 rounded-xl space-y-6 border border-gray-100">
            <h3 className="font-semibold text-lg flex items-center">
              <span className="w-8 h-8 rounded-lg bg-uai-light flex items-center justify-center mr-3">
                <Upload className="w-4 h-4 text-uai-teal" />
              </span>
              Informações Adicionais
            </h3>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">
                Por que você quer trabalhar na UaiSat?
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={4}
                placeholder="Conte-nos um pouco sobre suas motivações e o que você pode agregar à nossa equipe"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-uai-teal focus:border-transparent bg-white"
                required
              ></textarea>
            </div>
          </div>

          <button
            type="submit"
            disabled={submitting}
            className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 flex items-center justify-center group disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {submitting ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                Enviando...
              </>
            ) : (
              <>
                <Upload className="w-5 h-5 mr-2 transform group-hover:translate-y-1 transition-transform duration-300" />
                Enviar currículo
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CareerForm;