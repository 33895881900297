import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateId() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

// List of Brazilian capital cities with proper accents
export const CAPITAL_CITIES = [
  'Rio Branco',
  'Maceió',
  'Macapá',
  'Manaus',
  'Salvador',
  'Fortaleza',
  'Brasília',
  'Vitória',
  'Goiânia',
  'São Luís',
  'Cuiabá',
  'Campo Grande',
  'Belo Horizonte',
  'Belém',
  'João Pessoa',
  'Curitiba',
  'Recife',
  'Teresina',
  'Rio de Janeiro',
  'Natal',
  'Porto Alegre',
  'Porto Velho',
  'Boa Vista',
  'Florianópolis',
  'São Paulo',
  'Aracaju',
  'Palmas'
];

// Function to normalize text (remove accents and convert to lowercase)
export function normalizeText(text: string): string {
  return text.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .toLowerCase()
    .trim();
}

export function isCapitalCity(city: string): boolean {
  const normalizedCity = normalizeText(city);
  return CAPITAL_CITIES.some(capital => 
    normalizeText(capital).includes(normalizedCity) || 
    normalizedCity.includes(normalizeText(capital))
  );
}