import React, { useState } from 'react';
import { Send, Loader2 } from 'lucide-react';
import { sendEmail, TEMPLATES } from '../../lib/emailjs';
import toast from 'react-hot-toast';

interface NewsletterFormProps {
  onSuccess?: () => void;
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({ onSuccess }) => {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      await sendEmail({
        templateId: TEMPLATES.NEWSLETTER,
        templateParams: {
          subscriber_email: email
        }
      });

      toast.success('Inscrição realizada com sucesso!');
      setEmail('');
      onSuccess?.();
    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
      toast.error('Erro ao realizar inscrição. Tente novamente.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-2">
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Seu melhor email"
        className="px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-uai-teal"
        required
      />
      <button
        type="submit"
        disabled={submitting}
        className="bg-uai-teal hover:bg-opacity-90 text-white font-medium px-4 py-2 rounded-md transition-colors flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {submitting ? (
          <Loader2 className="animate-spin w-5 h-5" />
        ) : (
          <>
            <Send className="w-4 h-4 mr-2" />
            Inscrever
          </>
        )}
      </button>
    </form>
  );
};

export default NewsletterForm;