import React, { useState } from 'react';
import { Building2, Users, Globe2, BarChart3, Shield, Headset, Rocket, Target, Heart, X } from 'lucide-react';
import BusinessForm from '../components/forms/BusinessForm';

const Business: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [formType, setFormType] = useState<'consultant' | 'proposal' | 'corporate'>('consultant');

  const solutions = [
    {
      icon: <Users className="w-12 h-12 text-uai-teal" />,
      title: 'Planos Corporativos',
      description: 'Soluções personalizadas para equipes de todos os tamanhos com benefícios exclusivos.'
    },
    {
      icon: <Globe2 className="w-12 h-12 text-uai-teal" />,
      title: 'Cobertura Nacional',
      description: 'Mantenha sua equipe conectada em todo o Brasil com nossa rede de alta qualidade.'
    },
    {
      icon: <BarChart3 className="w-12 h-12 text-uai-teal" />,
      title: 'Gestão de Linhas',
      description: 'Painel administrativo completo para controle e monitoramento do uso corporativo.'
    },
    {
      icon: <Shield className="w-12 h-12 text-uai-teal" />,
      title: 'Segurança',
      description: 'Proteção avançada para dados empresariais e comunicações corporativas.'
    },
    {
      icon: <Headset className="w-12 h-12 text-uai-teal" />,
      title: 'Suporte Dedicado',
      description: 'Atendimento prioritário 24/7 para clientes corporativos.'
    }
  ];

  const handleOpenForm = (type: 'consultant' | 'proposal' | 'corporate') => {
    setFormType(type);
    setShowForm(true);
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[500px] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            alt="Business"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4">
          <div className="max-w-2xl">
            <h1 className="text-5xl font-bold text-white mb-6">Soluções Empresariais UaiSat</h1>
            <p className="text-xl text-white/90 mb-8">
              Conectividade de alta performance para impulsionar o crescimento do seu negócio
            </p>
            <button 
              onClick={() => handleOpenForm('consultant')}
              className="bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 inline-flex items-center group"
            >
              <span>Fale com um consultor</span>
              <svg 
                className="w-5 h-5 ml-2 transform transition-transform duration-300 group-hover:translate-x-1" 
                viewBox="0 0 24 24" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M5 12H19M19 12L12 5M19 12L12 19" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </section>

      {/* Solutions Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Nossas Soluções Corporativas</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Desenvolvemos soluções completas para atender às necessidades específicas da sua empresa
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {solutions.map((solution, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-2xl shadow-card hover:shadow-xl transition-all duration-300 group hover:-translate-y-1"
              >
                <div className="mb-6 transform group-hover:scale-110 transition-transform duration-300">
                  {solution.icon}
                </div>
                <h3 className="text-2xl font-bold mb-3">{solution.title}</h3>
                <p className="text-gray-600">{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold mb-6">Por que escolher a UaiSat?</h2>
              <div className="space-y-6">
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-12 h-12 bg-uai-light rounded-lg flex items-center justify-center">
                    <Target className="w-6 h-6 text-uai-teal" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-bold mb-2">Foco em resultados</h3>
                    <p className="text-gray-600">
                      Soluções personalizadas para maximizar a produtividade da sua equipe.
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-12 h-12 bg-uai-light rounded-lg flex items-center justify-center">
                    <Rocket className="w-6 h-6 text-uai-teal" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-bold mb-2">Tecnologia avançada</h3>
                    <p className="text-gray-600">
                      Infraestrutura de última geração com cobertura 4G+ e 5G.
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-12 h-12 bg-uai-light rounded-lg flex items-center justify-center">
                    <Heart className="w-6 h-6 text-uai-teal" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-bold mb-2">Atendimento humanizado</h3>
                    <p className="text-gray-600">
                      Suporte dedicado e personalizado para sua empresa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80"
                alt="Business Benefits"
                className="rounded-2xl shadow-xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/10 to-transparent rounded-2xl" />
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-r from-uai-dark to-uai-teal py-20 px-4">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-white mb-6">
            Pronto para transformar a conectividade da sua empresa?
          </h2>
          <p className="text-xl text-white/90 mb-8 max-w-2xl mx-auto">
            Nossa equipe está pronta para desenvolver a melhor solução para o seu negócio
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button 
              onClick={() => handleOpenForm('proposal')}
              className="bg-white text-uai-teal hover:bg-gray-100 font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300"
            >
              Solicitar proposta
            </button>
            <button 
              onClick={() => handleOpenForm('corporate')}
              className="bg-transparent border-2 border-white text-white hover:bg-white/10 font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300"
            >
              Ver planos corporativos
            </button>
          </div>
        </div>
      </section>

      {/* Form Modal */}
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="w-full max-w-2xl">
            <button
              onClick={() => setShowForm(false)}
              className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
            <BusinessForm type={formType} onSuccess={() => setShowForm(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Business;