import React from 'react';
import { Bell, Plus, Search, Trash2 } from 'lucide-react';

const Notifications: React.FC = () => {
  const notifications = [
    {
      id: '1',
      title: 'Promoção de Recargas',
      message: 'Nova promoção de recargas disponível',
      type: 'promo',
      date: '15/03/2024'
    },
    {
      id: '2',
      title: 'Manutenção Programada',
      message: 'Sistema em manutenção no dia 20/03',
      type: 'system',
      date: '14/03/2024'
    }
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Notificações</h1>
        <button className="bg-uai-teal hover:bg-uai-dark text-white px-4 py-2 rounded-lg flex items-center">
          <Plus className="w-5 h-5 mr-2" />
          Nova Notificação
        </button>
      </div>

      <div className="bg-white rounded-2xl shadow-sm">
        <div className="p-6 border-b">
          <div className="flex items-center">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Buscar notificações..."
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            </div>
          </div>
        </div>

        <div className="divide-y">
          {notifications.map((notification) => (
            <div key={notification.id} className="p-6 hover:bg-gray-50">
              <div className="flex items-start justify-between">
                <div className="flex items-start">
                  <div className="h-10 w-10 rounded-full bg-uai-light flex items-center justify-center">
                    <Bell className="h-5 w-5 text-uai-teal" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold">{notification.title}</h3>
                    <p className="text-gray-600">{notification.message}</p>
                    <p className="text-sm text-gray-500 mt-2">{notification.date}</p>
                  </div>
                </div>
                <button className="text-red-500 hover:text-red-700">
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notifications;