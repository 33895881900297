import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

interface LocationState {
  city: string;
  loading: boolean;
  error: string | null;
}

export function useLocation() {
  const [state, setState] = useState<LocationState>({
    city: 'São Paulo',
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchLocation = async (latitude: number, longitude: number, retryCount = 0): Promise<void> => {
      try {
        setState(prev => ({ ...prev, loading: true, error: null }));
        
        // Check for cached location first
        const cachedLocation = localStorage.getItem('cachedLocation');
        if (cachedLocation) {
          const { city, timestamp } = JSON.parse(cachedLocation);
          const oneHour = 60 * 60 * 1000;
          
          if (Date.now() - timestamp < oneHour) {
            setState({
              city,
              loading: false,
              error: null
            });
            return;
          }
        }

        // Create AbortController with a longer timeout
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 15000); // 15 seconds timeout

        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=pt-BR`,
          {
            headers: {
              'User-Agent': 'UaiSat Website (https://uaimovel.com.br)',
              'Accept': 'application/json'
            },
            signal: controller.signal,
            cache: 'no-cache' // Prevent caching issues
          }
        );

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data && data.address) {
          const cityName = data.address.city || 
                          data.address.town || 
                          data.address.village || 
                          data.address.municipality;
          
          if (cityName) {
            setState({
              city: cityName,
              loading: false,
              error: null
            });
            
            // Cache the location for 1 hour
            localStorage.setItem('cachedLocation', JSON.stringify({
              city: cityName,
              timestamp: Date.now()
            }));
            return;
          }
        }
        
        throw new Error('Cidade não encontrada');
      } catch (error) {
        console.error('Error fetching location:', error);
        
        // Retry up to 3 times with exponential backoff for network errors
        if (retryCount < 3 && (
          error instanceof TypeError || 
          (error instanceof Error && error.name === 'AbortError')
        )) {
          const delay = Math.min(1000 * Math.pow(2, retryCount), 5000);
          await new Promise(resolve => setTimeout(resolve, delay));
          return fetchLocation(latitude, longitude, retryCount + 1);
        }

        setState(prev => ({
          ...prev,
          loading: false,
          error: 'Não foi possível determinar sua localização'
        }));
      }
    };

    // Try to get cached location first
    const cachedLocation = localStorage.getItem('cachedLocation');
    if (cachedLocation) {
      const { city, timestamp } = JSON.parse(cachedLocation);
      const oneHour = 60 * 60 * 1000;
      
      if (Date.now() - timestamp < oneHour) {
        setState({
          city,
          loading: false,
          error: null
        });
        return;
      }
    }

    // Then try geolocation with increased timeout
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchLocation(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          // Handle specific geolocation errors
          let errorMessage = 'Não foi possível acessar sua localização';
          
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = 'Localização não autorizada. Usando localização padrão.';
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = 'Informações de localização indisponíveis';
              break;
            case error.TIMEOUT:
              errorMessage = 'Tempo esgotado ao obter localização. Usando localização padrão.';
              break;
          }

          // Don't show error toast for permission denied or timeout
          if (error.code !== error.PERMISSION_DENIED && error.code !== error.TIMEOUT) {
            toast.error(errorMessage);
          }

          setState(prev => ({
            ...prev,
            loading: false,
            error: errorMessage
          }));
        },
        {
          enableHighAccuracy: false, // Set to false to improve response time
          timeout: 30000, // Increased timeout to 30 seconds
          maximumAge: 300000 // Cache location for 5 minutes
        }
      );
    } else {
      setState(prev => ({
        ...prev,
        loading: false,
        error: 'Seu navegador não suporta geolocalização'
      }));
    }
  }, []);

  return state;
}