import React, { useState } from 'react';
import { Shield, ArrowLeft, Key, Smartphone, Eye, EyeOff } from 'lucide-react';

const Privacy: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle password change
  };

  return (
    <div>
      <div className="flex items-center mb-6">
        <button 
          onClick={() => window.history.back()}
          className="mr-4 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="w-6 h-6" />
        </button>
        <h1 className="text-2xl font-bold">Privacidade e Segurança</h1>
      </div>

      <div className="space-y-6">
        {/* Password Change */}
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <div className="flex items-center mb-6">
            <Key className="w-6 h-6 text-uai-teal mr-2" />
            <h2 className="text-lg font-semibold">Alterar Senha</h2>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Senha atual
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={formData.currentPassword}
                  onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  {showPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Nova senha
              </label>
              <input
                type="password"
                value={formData.newPassword}
                onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Confirmar nova senha
              </label>
              <input
                type="password"
                value={formData.confirmPassword}
                onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-3 px-6 rounded-lg transition-colors"
            >
              Alterar senha
            </button>
          </form>
        </div>

        {/* Device Management */}
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <div className="flex items-center mb-6">
            <Smartphone className="w-6 h-6 text-uai-teal mr-2" />
            <h2 className="text-lg font-semibold">Dispositivos Conectados</h2>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <p className="font-medium">iPhone 13 Pro</p>
                <p className="text-sm text-gray-500">Último acesso: há 2 minutos</p>
              </div>
              <button className="text-red-500 hover:text-red-600 text-sm font-medium">
                Remover
              </button>
            </div>

            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <p className="font-medium">MacBook Pro</p>
                <p className="text-sm text-gray-500">Último acesso: há 1 hora</p>
              </div>
              <button className="text-red-500 hover:text-red-600 text-sm font-medium">
                Remover
              </button>
            </div>
          </div>
        </div>

        {/* Privacy Settings */}
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <div className="flex items-center mb-6">
            <Shield className="w-6 h-6 text-uai-teal mr-2" />
            <h2 className="text-lg font-semibold">Configurações de Privacidade</h2>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Histórico de recargas</p>
                <p className="text-sm text-gray-500">Manter histórico de recargas por 12 meses</p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" defaultChecked />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-uai-light rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-uai-teal"></div>
              </label>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Dados de uso</p>
                <p className="text-sm text-gray-500">Compartilhar dados de uso para melhorar o serviço</p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-uai-light rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-uai-teal"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;