import React, { useState } from 'react';
import { Search, MapPin, Phone, Filter, Store, Tag, CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

interface Vendor {
  id: string;
  name: string;
  business_name: string;
  city: string;
  state: string;
  phone: string;
  sells_prepaid: boolean;
  sells_control: boolean;
  address: string;
  photo_url?: string;
}

// Mock data - replace with API call
const vendors: Vendor[] = [
  {
    id: '1',
    name: "Nadinho's Restaurante",
    business_name: "Nadinho's Restaurante",
    city: 'Ubaporanga',
    state: 'MG',
    phone: '(33) 3323-1125',
    sells_prepaid: true,
    sells_control: true,
    address: 'BR-116, 510 - Centro',
    photo_url: 'https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80'
  },
  {
    id: '2',
    name: 'BANCA DA PRAÇA',
    business_name: 'BANCA DA PRAÇA',
    city: 'Timóteo',
    state: 'MG',
    phone: '',
    sells_prepaid: true,
    sells_control: false,
    address: 'Praça Primeiro de Maio, 240 - Centro',
    photo_url: 'https://images.unsplash.com/photo-1613665813446-82a78c468a1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80'
  }
];

const Vendors: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    prepaid: false,
    control: false
  });

  const filteredVendors = vendors.filter(vendor => {
    const matchesSearch = 
      vendor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vendor.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vendor.state.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilters = 
      (!filters.prepaid && !filters.control) ||
      (filters.prepaid && vendor.sells_prepaid) ||
      (filters.control && vendor.sells_control);

    return matchesSearch && matchesFilters;
  });

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[300px] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1556740738-b6a63e27c4df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            alt="Vendors"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4">
          <div className="max-w-2xl">
            <h1 className="text-5xl font-bold text-white mb-6">Vendedores UaiSat</h1>
            <p className="text-xl text-white/90">
              Encontre o vendedor mais próximo de você
            </p>
          </div>
        </div>
      </section>

      {/* Search and Filters */}
      <section className="py-8 px-4 bg-white border-b">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
            {/* Search */}
            <div className="w-full md:w-96 relative">
              <input
                type="text"
                placeholder="Buscar por nome ou cidade..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              />
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            </div>

            {/* Filters */}
            <div className="flex items-center space-x-4">
              <span className="flex items-center text-gray-600">
                <Filter className="w-5 h-5 mr-2" />
                Filtrar por:
              </span>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={filters.prepaid}
                  onChange={(e) => setFilters({ ...filters, prepaid: e.target.checked })}
                  className="rounded text-uai-teal focus:ring-uai-teal"
                />
                <span>Pré-pago</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={filters.control}
                  onChange={(e) => setFilters({ ...filters, control: e.target.checked })}
                  className="rounded text-uai-teal focus:ring-uai-teal"
                />
                <span>Controle</span>
              </label>
            </div>
          </div>
        </div>
      </section>

      {/* Vendors List */}
      <section className="py-12 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredVendors.map((vendor) => (
              <div 
                key={vendor.id}
                className="bg-white rounded-2xl shadow-card hover:shadow-xl transition-all duration-300 overflow-hidden group"
              >
                {/* Vendor Image */}
                <div className="relative h-48 overflow-hidden">
                  <img
                    src={vendor.photo_url || 'https://images.unsplash.com/photo-1582647509711-c8aa8eb7c7a4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80'}
                    alt={vendor.business_name}
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
                    <div className="flex gap-2">
                      {vendor.sells_prepaid && (
                        <span className="px-2 py-1 bg-white/20 backdrop-blur-sm rounded-lg text-white text-sm flex items-center">
                          <Tag className="w-4 h-4 mr-1" />
                          Pré-pago
                        </span>
                      )}
                      {vendor.sells_control && (
                        <span className="px-2 py-1 bg-white/20 backdrop-blur-sm rounded-lg text-white text-sm flex items-center">
                          <Tag className="w-4 h-4 mr-1" />
                          Controle
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/* Vendor Info */}
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{vendor.business_name}</h3>
                  
                  <div className="space-y-3 text-gray-600">
                    <div className="flex items-start">
                      <MapPin className="w-5 h-5 text-uai-teal mr-2 flex-shrink-0 mt-0.5" />
                      <p>{vendor.address}, {vendor.city} - {vendor.state}</p>
                    </div>
                    
                    {vendor.phone && (
                      <div className="flex items-center">
                        <Phone className="w-5 h-5 text-uai-teal mr-2" />
                        <p>{vendor.phone}</p>
                      </div>
                    )}
                  </div>

                  <div className="mt-6 pt-6 border-t border-gray-100">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center text-uai-teal">
                        <Store className="w-5 h-5 mr-2" />
                        <span className="font-medium">Vendedor oficial</span>
                      </div>
                      <CheckCircle className="w-5 h-5 text-uai-teal" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {filteredVendors.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-600 text-lg mb-4">
                Nenhum vendedor encontrado com os filtros selecionados.
              </p>
              <Link
                to="/seja-revendedor"
                className="inline-flex items-center text-uai-teal hover:text-uai-dark font-medium"
              >
                <Store className="w-5 h-5 mr-2" />
                Quero ser um vendedor
              </Link>
            </div>
          )}
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">Quer se tornar um vendedor UaiSat?</h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Junte-se à nossa rede de vendedores e comece a lucrar com a operadora que mais cresce no Brasil
          </p>
          <Link
            to="/seja-revendedor"
            className="inline-flex items-center bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-xl transition-all duration-300 group"
          >
            <Store className="w-5 h-5 mr-2" />
            <span>Quero ser vendedor</span>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Vendors;