import React, { useState, useEffect, useRef } from 'react';
import { banners } from '../lib/data';
import { useNavigate } from 'react-router-dom';

const Banner: React.FC = () => {
  const navigate = useNavigate();
  const [currentBanner, setCurrentBanner] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const intervalRef = useRef<number | null>(null);
  const transitionTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
    }
    
    if (banners.length > 0) {
      const id = window.setInterval(() => {
        handleNext();
      }, 7000);
      
      intervalRef.current = id;
    }
    
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
      }
      if (transitionTimeoutRef.current) {
        window.clearTimeout(transitionTimeoutRef.current);
      }
    };
  }, [banners.length]);

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentBanner((prev) => (prev + 1) % banners.length);
      transitionTimeoutRef.current = window.setTimeout(() => {
        setIsTransitioning(false);
      }, 1000);
    }
  };

  const handleDotClick = (index: number) => {
    if (!isTransitioning && index !== currentBanner) {
      setIsTransitioning(true);
      setCurrentBanner(index);
      transitionTimeoutRef.current = window.setTimeout(() => {
        setIsTransitioning(false);
      }, 1000);
    }
  };

  const handleClick = (link: string, e: React.MouseEvent) => {
    e.preventDefault();
    
    // Handle internal links (like /#planos)
    if (link.startsWith('/#')) {
      const element = document.querySelector(link.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }
    
    // Handle other internal links
    if (!link.startsWith('http')) {
      navigate(link);
      return;
    }

    // Handle external links
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const activeBanners = banners.filter(banner => banner.active);

  if (activeBanners.length === 0) {
    return null;
  }

  return (
    <div className="relative h-[500px] md:h-[600px] lg:h-[700px] overflow-hidden bg-gray-900">
      {/* Background Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/30 via-transparent to-black/30 z-10" />
      
      {activeBanners.map((banner, index) => (
        <div
          key={banner.id}
          className={`absolute inset-0 transition-all duration-1000 ease-in-out ${
            index === currentBanner 
              ? 'opacity-100 translate-x-0 scale-100' 
              : 'opacity-0 translate-x-full scale-105'
          }`}
        >
          {/* Banner Background */}
          <div 
            className="absolute inset-0 bg-cover bg-center transition-transform duration-[2s] ease-out"
            style={{
              backgroundImage: `url(${banner.image_url})`,
              transform: index === currentBanner ? 'scale(1.05)' : 'scale(1)',
            }}
          />
          
          {/* Content Container with max width */}
          <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-transparent">
            <div className="max-w-7xl mx-auto px-4 h-full flex items-center relative">
              {/* Content */}
              <div className="max-w-xl">
                {/* Title with animated underline */}
                <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 leading-tight relative">
                  {banner.title}
                  <span 
                    className="absolute bottom-0 left-0 w-24 h-1 bg-gradient-to-r from-white to-transparent"
                    style={{
                      transform: index === currentBanner ? 'scaleX(1)' : 'scaleX(0)',
                      transition: 'transform 1s ease-out 0.5s',
                      transformOrigin: 'left'
                    }}
                  />
                </h1>
                
                {/* Description with fade-in animation */}
                <p 
                  className="text-xl md:text-2xl text-white/90 mb-8 leading-relaxed"
                  style={{
                    opacity: index === currentBanner ? 1 : 0,
                    transform: index === currentBanner ? 'translateY(0)' : 'translateY(20px)',
                    transition: 'all 1s ease-out 0.7s'
                  }}
                >
                  {banner.description}
                </p>
                
                {/* CTA Button with hover effects */}
                <button 
                  onClick={(e) => handleClick(banner.link, e)}
                  className="group relative overflow-hidden bg-white bg-opacity-10 hover:bg-opacity-20 backdrop-blur-sm text-white font-bold py-4 px-8 rounded-xl text-lg transition-all duration-300 inline-flex items-center"
                  style={{ backgroundColor: banner.color }}
                >
                  <span className="relative z-10">{banner.cta}</span>
                  <svg 
                    className="w-6 h-6 ml-2 transform transition-transform duration-300 group-hover:translate-x-1" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path 
                      d="M5 12H19M19 12L12 5M19 12L12 19" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    />
                  </svg>
                  
                  {/* Button hover effect */}
                  <span className="absolute inset-0 bg-white/20 transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
      
      {/* Progress Dots */}
      {activeBanners.length > 1 && (
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-3 z-20">
          {activeBanners.map((_, index) => (
            <button
              key={index}
              onClick={() => handleDotClick(index)}
              className={`relative h-3 transition-all duration-300 rounded-full overflow-hidden ${
                index === currentBanner ? 'w-12 bg-white' : 'w-3 bg-white/50 hover:bg-white/80'
              }`}
              aria-label={`Go to banner ${index + 1}`}
            >
              {index === currentBanner && (
                <span 
                  className="absolute inset-0 bg-gradient-to-r from-transparent via-white/50 to-transparent animate-shimmer"
                  style={{
                    backgroundSize: '200% 100%',
                    animation: 'shimmer 2s infinite'
                  }}
                />
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Banner;