import React from 'react';
import { CheckCircle } from 'lucide-react';

const AppDownload: React.FC = () => {
  const features = [
    'Consulta de consumo em tempo real',
    'Recarga rápida e segura',
    'Suporte 24h via chat',
    'Gerenciamento de serviços',
    'Histórico detalhado',
    'Pagamento de faturas'
  ];

  return (
    <section className="relative py-20 px-4 bg-gradient-to-br from-uai-dark via-uai-teal to-uai-dark overflow-hidden">
      {/* Animated background effects */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-96 h-96 bg-white rounded-full mix-blend-overlay filter blur-3xl animate-blob" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-white rounded-full mix-blend-overlay filter blur-3xl animate-blob animation-delay-2000" />
        <div className="absolute bottom-0 left-1/2 w-96 h-96 bg-white rounded-full mix-blend-overlay filter blur-3xl animate-blob animation-delay-4000" />
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          {/* Content */}
          <div className="text-white relative z-10">
            <h2 className="text-5xl font-bold mb-8 leading-tight">
              Baixe nosso aplicativo e tenha o controle total
            </h2>
            <p className="text-xl mb-10 text-white/90 leading-relaxed">
              Gerencie sua linha UaiSat direto do seu smartphone. Recargas, consumo, suporte e muito mais na palma da sua mão.
            </p>

            <div className="grid grid-cols-2 gap-6 mb-10">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <div className="flex-shrink-0 w-8 h-8 rounded-lg bg-white/10 flex items-center justify-center mr-3">
                    <CheckCircle className="w-5 h-5 text-white" />
                  </div>
                  <span className="text-lg text-white/90">{feature}</span>
                </div>
              ))}
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <a 
                href="https://play.google.com/store/apps/details?id=com.uaisat.appmovel&pcampaignid=web_share" 
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white hover:bg-gray-50 transition-all duration-300 px-8 py-4 rounded-xl flex items-center justify-center group shadow-lg hover:shadow-xl transform hover:-translate-y-1"
              >
                <img 
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" 
                  alt="Get it on Google Play" 
                  className="h-8 transform group-hover:scale-105 transition-transform duration-300"
                />
              </a>
              <div className="bg-white/10 backdrop-blur-sm border border-white/20 px-8 py-4 rounded-xl flex items-center justify-center text-white/90">
                <span>Em breve na App Store</span>
              </div>
            </div>
          </div>

          {/* App Image */}
          <div className="relative">
            <div className="relative z-10 transform hover:scale-105 transition-transform duration-700 ease-in-out">
              <img 
                src="https://cloud.uaisat.app.br/03/Design-sem-nome-5.png"
                alt="UaiSat App Interface" 
                className="w-full h-auto rounded-[2.5rem] shadow-2xl"
              />
              {/* Reflection effect */}
              <div className="absolute inset-0 bg-gradient-to-t from-white/20 to-transparent rounded-[2.5rem] mix-blend-overlay" />
            </div>
            {/* Glow effects */}
            <div className="absolute inset-0 bg-gradient-radial from-uai-teal/30 to-transparent rounded-[2.5rem] transform scale-105 blur-2xl" />
            <div className="absolute inset-0 bg-gradient-to-t from-uai-dark/30 to-transparent rounded-[2.5rem] transform scale-105 blur-xl" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppDownload;