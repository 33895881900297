import React from 'react';
import { Shield, Lock, Eye, UserCheck, Database, Bell } from 'lucide-react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="py-16 px-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-8">Política de Privacidade</h1>
        
        <div className="prose prose-lg max-w-none">
          {/* Introduction */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-4">1. Introdução</h2>
            <p className="mb-4">
              A privacidade dos visitantes do nosso site é muito importante para nós, e estamos comprometidos em protegê-la.
            </p>
            <p className="mb-4">
              Esta política explica o que faremos com suas informações pessoais.
            </p>
            <p>
              Consentir o uso de cookies de acordo com os termos desta política quando você acessar nosso site pela vez, nos permite usar cookies toda vez que você acessar nosso site. Esses cookies são ferramentas técnicas que permitem que sua experiência ao navegar em nosso site seja a melhor possível.
            </p>
          </section>

          {/* Data Collection */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Shield className="w-6 h-6 text-uai-teal mr-2" />
              2. Coleta de Dados Pessoais
            </h2>
            <div className="space-y-4">
              {[...Array(11)].map((_, i) => (
                <p key={i} className="flex items-start">
                  <span className="mr-2">2.{i + 1}</span>
                  {[
                    'Informações sobre o seu computador, incluindo seu endereço IP, localização geográfica, tipo e versão do navegador e sistema operacional;',
                    'Informações sobre suas visitas e uso deste site, incluindo fonte de referência, duração da visita, visualizações de página e caminhos de navegação no site;',
                    'Informações como seu endereço de e-mail, que você digita quando se cadastra em nosso site;',
                    'Informações que você digita ao criar um perfil em nosso site – por exemplo, seu nome, fotos de perfil, gênero, data de nascimento, status de relacionamento, interesses e hobbies, informações educacionais e de emprego;',
                    'Informações como seu nome e endereço de e-mail, que você digita para configurar assinaturas de nossos e-mails e/ou newsletters;',
                    'Informações que você fornece ao digitar durante o uso dos serviços em nosso site;',
                    'Informações geradas ao usar nosso site, incluindo quando, com que frequência e em que situações você o utiliza e nele navega;',
                    'Informações relacionadas a tudo o que você compra, serviços que utiliza ou transações que realizam através do nosso site, incluindo nome, endereço, número de telefone, endereço de e-mail e dados do cartão de crédito;',
                    'Informações que você publica em nosso site com a intenção de publicá-las na internet, incluindo seu nome de usuário, fotos de perfil e o conteúdo de suas publicações;',
                    'Informações contidas em quaisquer comunicações que você enviar por e-mail ou através de nosso site, incluindo o conteúdo e os metadados da comunicação;',
                    'Qualquer outra informação pessoal que você nos envie.'
                  ][i]}
                </p>
              ))}
            </div>
            <p className="mt-6">
              Antes de divulgarmos dados pessoais de outra pessoa, você deve obter o consentimento dessa pessoa para a divulgação e o processamento dessas informações pessoais de acordo com esta política. Deve, também, informar especificamente da coleta e nos enviar o consentimento de terceiros nestes moldes.
            </p>
          </section>

          {/* Data Usage */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Lock className="w-6 h-6 text-uai-teal mr-2" />
              3. Uso de suas informações pessoais
            </h2>
            <p className="mb-4">
              As informações pessoais que são enviadas por meio de nosso site serão usadas para os fins especificados nesta Política ou nas páginas relevantes do site.
            </p>
            <p className="mb-4">Podemos usar suas informações pessoais para o seguinte:</p>
            <div className="space-y-2">
              {[...Array(14)].map((_, i) => (
                <p key={i} className="flex items-start">
                  <span className="mr-2">3.{i + 1}</span>
                  {[
                    'Administrar nosso site e nossos negócios envolvem uma prestação adequada de serviços;',
                    'Personalizar nosso site para você, tornando a navegação mais amistosa;',
                    'Possibilitar o uso dos serviços disponíveis em nosso site;',
                    'Enviar produtos adquiridos através do nosso site;',
                    'Prestar serviços adquiridos através do nosso site;',
                    'Enviar extratos, faturas e lembretes de pagamento, bem como cobrança de seus pagamentos;',
                    'Enviar comunicações comerciais que não sejam de marketing;',
                    'Enviar notificações por e-mail solicitado especificamente para você;',
                    'Enviar comunicações de marketing relacionadas aos nossos negócios ou aos negócios de terceiros cuidadosamente selecionados que sejam do seu interesse, por correio ou, onde você especificamente entrega com isso, por e-mail ou tecnologia semelhante (você pode nos informar a qualquer momento se não quiser mais receber comunicações de marketing);',
                    'Fornecer a terceiros informações estatísticas sobre nossos usuários (mas esses terceiros não poderão identificar nenhum usuário individual a partir dessas informações);',
                    'Lidar com perguntas e pedidos feitos por você ou sobre você em relação ao nosso site;',
                    'Manter nosso site seguro e evitar fraudes;',
                    'Verificar a conformidade com os termos e condições que regem o uso do nosso site (incluindo o monitoramento de mensagens privadas enviadas por meio do serviço de mensagens privadas do nosso site);',
                    'Outros usos que serão devidamente informados e cuja assinatura será especialmente recebida'
                  ][i]}
                </p>
              ))}
            </div>
          </section>

          {/* Data Disclosure */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Eye className="w-6 h-6 text-uai-teal mr-2" />
              4. Divulgação de Dados Pessoais
            </h2>
            <p className="mb-4">
              Podemos divulgar suas informações pessoais a qualquer um de nossos funcionários, executivos, supervisores, consultores profissionais, agentes, fornecedores ou subcontratados em conformidade com o necessário para os fins estabelecidos nesta política.
            </p>
            <p className="mb-4">
              Podemos divulgar suas informações pessoais a qualquer membro de nosso grupo de empresas (isso significa nossas subsidiárias, nossa holding e todas as suas subsidiárias) em conformidade com o necessário para os fins estabelecidos nesta política.
            </p>
            <p className="mb-4">Podemos divulgar suas informações pessoais:</p>
            <div className="space-y-2">
              {[...Array(4)].map((_, i) => (
                <p key={i} className="flex items-start">
                  <span className="mr-2">4.{i + 1}</span>
                  {[
                    'Na medida em que somos obrigados a fazê-lo por lei;',
                    'Em relação a qualquer processo judicial em andamento ou potencial, desde que haja ordem específica e fundamentada nesse sentido;',
                    'Para estabelecer, exercer ou defender nossos direitos legais (incluindo fornecer informações a terceiros para fins de prevenção de fraudes e redução do risco de crédito), ocasião em que você será imediatamente informado;',
                    'Ao comprador (ou comprador em potencial) de qualquer negócio ou ativo que estejamos vendendo (ou contemplando vender);'
                  ][i]}
                </p>
              ))}
            </div>
            <p className="mt-4">
              Nunca forneceremos suas informações pessoais a terceiros a não ser mediante seu consentimento ou nos casos estabelecidos Política de Privacidade.
            </p>
          </section>

          {/* International Data Transfers */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Database className="w-6 h-6 text-uai-teal mr-2" />
              5. Transferências Internacionais de Dados Pessoais
            </h2>
            <div className="space-y-4">
              <p>
                As informações que coletamos podem ser armazenadas, processadas e detalhadas entre qualquer um dos países em que operamos, a fim de nos permitir usar as informações de acordo com esta Política de Privacidade, nos termos do artigo 33 da Lei nº 13.709/2018, a Lei Geral de Proteção de Dados, ou LGPD.
              </p>
              <p>
                As informações que coletamos podem ser detalhadas para os seguintes países que não possuem leis de proteção de dados equivalentes às vigentes no Espaço Econômico Europeu: Estados Unidos da América, Rússia, Japão, China e Índia.
              </p>
              <p>
                As informações pessoais que você publica em nosso site ou envia para publicação em nosso site podem estar disponíveis, através da internet, em todo o mundo. Não podemos impedir o uso ou uso indevido de tais informações por terceiros.
              </p>
            </div>
          </section>

          {/* Data Retention */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Database className="w-6 h-6 text-uai-teal mr-2" />
              6. Retenção de Dados Pessoais
            </h2>
            <div className="space-y-4">
              <p>
                Esta Seção define nossas políticas e procedimentos de retenção e tratamento de dados pessoais, projetados para ajudar a garantir o cumprimento de nossas obrigações legais em relação ao tratamento e exclusão de dados pessoais.
              </p>
              <p>
                As informações pessoais que processamos para qualquer propósito ou propósito não devem ser mantidas por mais tempo do que o necessário para esse propósito ou propósito.
              </p>
              <p>
                Não obstante outras disposições desta Seção reteremos documentos (incluindo documentos eletrônicos) que contenham dados pessoais:
              </p>
              <div className="space-y-2 pl-6">
                <p>6.1 Na medida em que somos obrigados a fazê-lo por lei;</p>
                <p>6.2 Acreditamos que os documentos podem ser relevantes para qualquer processo judicial em andamento ou potencial; e</p>
                <p>6.3 Para estabelecer, exercer ou defender nossos direitos legais e nosso interesse legítimo (incluindo fornecer informações a terceiros para fins de prevenção de fraudes e redução do risco de crédito).</p>
              </div>
            </div>
          </section>

          {/* Data Security */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Shield className="w-6 h-6 text-uai-teal mr-2" />
              7. Segurança de seus dados pessoais
            </h2>
            <div className="space-y-4">
              <p>
                Tomaremos as precauções técnicas e organizacionais para evitar perda, mau uso ou alteração de seus dados pessoais.
              </p>
              <p>
                Armazenaremos todas as suas informações pessoais fornecidas em nossos servidores seguros (protegidos por senha e firewall).
              </p>
              <p>
                Todas as transações financeiras eletrônicas realizadas através do nosso site serão protegidas por tecnologia de criptografia.
              </p>
              <p>
                Sempre investiremos nas mais diretrizes de segurança cibernética para garantir a incolumidade de seus dados pessoais.
              </p>
              <p>
                Quaisquer incidentes de segurança de dados que envolvam seus dados serão informados rapidamente a você. Tomaremos medidas preventivas imediatas para excluir ou mitigar os respectivos efeitos.
              </p>
            </div>
          </section>

          {/* Policy Updates */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Bell className="w-6 h-6 text-uai-teal mr-2" />
              8. Alterações da Política de Privacidade
            </h2>
            <div className="space-y-4">
              <p>
                Podemos atualizar esta Política de Privacidade periodicamente, através da publicação de uma nova versão em nosso site.
              </p>
              <p>
                Você deve verificar esta página relacionada para garantir que inclua quaisquer alterações nesta política. Podemos notificá-lo sobre alterações nesta política por e-mail ou através do sistema de mensagens privadas em nosso site.
              </p>
              <p>
                Em caso de dúvidas sobre esta Política de Privacidade você sempre poderá entrar em contato por meio do site.
              </p>
            </div>
          </section>

          {/* User Rights */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <UserCheck className="w-6 h-6 text-uai-teal mr-2" />
              9. Seus Direitos
            </h2>
            <div className="space-y-4">
              <p>
                Você pode nos instruir a fornecer qualquer informação pessoal que tenhamos sobre você. Pode, também, na forma da lei, pedir verificação, alteração, correção, portabilidade e exclusão de seus dados pessoais.
              </p>
              <p>
                Tratamos suas informações pessoais solicitadas na extensão permitida por lei, especialmente as leis de proteção de dados.
              </p>
              <p>
                Você pode nos instruir a qualquer momento para não processar suas informações pessoais para fins de marketing. Somente utilizaremos seus dados pessoais para apresentar nossos produtos e serviços mediante aviso prévio, expresso e informado.
              </p>
              <p>
                Seus dados pessoais poderão ser solicitados a qualquer momento por você. Os direitos do titular, previstos nos artigos 17 a 20 da LGPD, poderão ser exercidos a qualquer tempo e sua concretização será por nós providenciada com a maior brevidade possível.
              </p>
            </div>
          </section>

          {/* Third-party Websites */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-4">10. Sites de Terceiros</h2>
            <div className="space-y-4">
              <p>Nosso site inclui links para e detalhes sobre sites de Terceiros.</p>
              <p>Não temos controle sobre e não somos responsáveis ​​pelas políticas e práticas de privacidade de terceiros.</p>
            </div>
          </section>

          {/* Information Updates */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-4">11. Atualização de Informações</h2>
            <p>
              Informe-nos as informações pessoais que mantemos sobre você precisam ser corrigidas ou atualizadas.
            </p>
          </section>

          {/* Cookies */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-4">12. Cookies</h2>
            <p>Nosso site usa cookies.</p>
          </section>

          {/* Last Update */}
          <div className="text-sm text-gray-600 mt-12">
            <p>Última atualização: Março de 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;