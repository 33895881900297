import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import toast from 'react-hot-toast';

interface UserProfile {
  cpf: string;
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
}

interface AuthState {
  isAuthenticated: boolean;
  isAdmin: boolean;
  user: UserProfile | null;
  login: (cpf: string, password: string) => Promise<boolean>;
  logout: () => void;
  updateUserProfile: (profile: UserProfile) => Promise<void>;
}

// Admin credentials
const ADMIN_CPF = '08257598623';
const ADMIN_PASSWORD = '08257598623';

export const useAuth = create<AuthState>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      isAdmin: false,
      user: null,
      login: async (cpf: string, password: string) => {
        try {
          // Check admin credentials
          if (cpf === ADMIN_CPF && password === ADMIN_PASSWORD) {
            set({
              isAuthenticated: true,
              isAdmin: true,
              user: {
                cpf,
                name: 'Administrador',
                email: 'admin@uaisat.com'
              }
            });
            return true;
          }

          // Check regular user credentials
          if (cpf && password) {
            set({
              isAuthenticated: true,
              isAdmin: false,
              user: {
                cpf
              }
            });
            return true;
          }

          throw new Error('CPF ou senha inválidos');
        } catch (error) {
          console.error('Login error:', error);
          throw error;
        }
      },
      logout: () => {
        set({
          isAuthenticated: false,
          isAdmin: false,
          user: null
        });
      },
      updateUserProfile: async (profile: UserProfile) => {
        try {
          // Here you would typically make an API call to update the profile
          // For now, we'll just update the local state
          set(state => ({
            ...state,
            user: {
              ...state.user,
              ...profile
            }
          }));
        } catch (error) {
          console.error('Error updating profile:', error);
          throw error;
        }
      }
    }),
    {
      name: 'uaisat-auth'
    }
  )
);

// Protected route hook
export function useRequireAuth(requireAdmin = false) {
  const { isAuthenticated, isAdmin } = useAuth();

  if (!isAuthenticated) {
    toast.error('Você precisa fazer login para acessar esta página');
    return false;
  }

  if (requireAdmin && !isAdmin) {
    toast.error('Você não tem permissão para acessar esta página');
    return false;
  }

  return true;
}