import React, { useState } from 'react';
import { Send, Loader2, Phone, Mail, MapPin, MessageSquare } from 'lucide-react';
import { sendContactForm } from '../lib/api';
import { IMaskInput } from 'react-imask';
import toast from 'react-hot-toast';

const Contact: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nome_completo: '',
    email: '',
    telefone: '',
    assunto: '',
    mensagem: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await sendContactForm(formData);
      
      toast.success('Mensagem enviada com sucesso!');
      
      // Reset form
      setFormData({
        nome_completo: '',
        email: '',
        telefone: '',
        assunto: '',
        mensagem: ''
      });
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error(error instanceof Error ? error.message : 'Erro ao enviar mensagem');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-16 px-4">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Entre em Contato</h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Estamos aqui para ajudar. Envie sua mensagem e nossa equipe responderá o mais breve possível.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div className="bg-white rounded-2xl shadow-xl p-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nome completo
                  </label>
                  <input
                    type="text"
                    value={formData.nome_completo}
                    onChange={(e) => setFormData({ ...formData, nome_completo: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    required
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Telefone
                  </label>
                  <IMaskInput
                    mask="(00) 00000-0000"
                    value={formData.telefone}
                    unmask={true}
                    onAccept={(value) => setFormData({ ...formData, telefone: value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Assunto
                  </label>
                  <select
                    value={formData.assunto}
                    onChange={(e) => setFormData({ ...formData, assunto: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    required
                  >
                    <option value="">Selecione um assunto</option>
                    <option value="Suporte Técnico">Suporte Técnico</option>
                    <option value="Vendas">Vendas</option>
                    <option value="Financeiro">Financeiro</option>
                    <option value="Outros">Outros assuntos</option>
                  </select>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Mensagem
                </label>
                <textarea
                  value={formData.mensagem}
                  onChange={(e) => setFormData({ ...formData, mensagem: e.target.value })}
                  rows={6}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                  required
                ></textarea>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 flex items-center justify-center group disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                    Enviando...
                  </>
                ) : (
                  <>
                    <Send className="w-5 h-5 mr-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                    Enviar mensagem
                  </>
                )}
              </button>
            </form>
          </div>

          {/* Contact Info */}
          <div className="space-y-8">
            <div className="bg-white rounded-2xl shadow-xl p-8">
              <h2 className="text-2xl font-bold mb-6">Informações de Contato</h2>
              
              <div className="space-y-6">
                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-full bg-uai-light flex items-center justify-center mr-4">
                    <Phone className="w-5 h-5 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg">Telefone</h3>
                    <p className="text-gray-600">*288 ou 10543</p>
                    <p className="text-sm text-gray-500 mt-1">Atendimento 24h</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-full bg-uai-light flex items-center justify-center mr-4">
                    <Mail className="w-5 h-5 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg">Email</h3>
                    <p className="text-gray-600">contato@uaimovel.com.br</p>
                    <p className="text-sm text-gray-500 mt-1">Resposta em até 24h</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-full bg-uai-light flex items-center justify-center mr-4">
                    <MapPin className="w-5 h-5 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg">Endereço</h3>
                    <p className="text-gray-600">Av. Paulista 1636</p>
                    <p className="text-gray-600">Bela Vista, São Paulo-SP</p>
                    <p className="text-gray-600">01310-200</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-2xl shadow-xl p-8">
              <h2 className="text-2xl font-bold mb-6">Atendimento</h2>
              
              <div className="space-y-6">
                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-full bg-uai-light flex items-center justify-center mr-4">
                    <MessageSquare className="w-5 h-5 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg">Chat Online</h3>
                    <p className="text-gray-600">Disponível no aplicativo</p>
                    <p className="text-sm text-gray-500 mt-1">Segunda a Sábado, 8h às 20h</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-full bg-uai-light flex items-center justify-center mr-4">
                    <Phone className="w-5 h-5 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg">Central de Atendimento</h3>
                    <p className="text-gray-600">*288 ou 10543</p>
                    <p className="text-sm text-gray-500 mt-1">24 horas por dia, 7 dias por semana</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;