import React, { useState } from 'react';
import { MapPin, Search, Phone, Clock, ArrowRight, MapIcon } from 'lucide-react';

interface Store {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  phone?: string;
  hours?: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

const stores: Store[] = [
  {
    id: '1',
    name: "Nadinho's Restaurante",
    address: 'BR-116, 510 - Centro',
    city: 'Ubaporanga',
    state: 'MG',
    phone: '(33) 3323-1125',
    coordinates: {
      lat: -19.6351,
      lng: -42.1073
    }
  },
  {
    id: '2',
    name: 'BANCA DA PRAÇA',
    address: 'Praça Primeiro de Maio, 240 - Centro',
    city: 'Timóteo',
    state: 'MG',
    coordinates: {
      lat: -19.5814,
      lng: -42.6471
    }
  }
];

const Stores: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);

  const filteredStores = stores.filter(store => 
    store.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    store.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
    store.city.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleStoreSelect = (store: Store) => {
    setSelectedStore(store);
  };

  const getMapUrl = (store: Store | null) => {
    const currentStore = store || stores[0];
    const zoom = 16;
    return `https://www.openstreetmap.org/export/embed.html?bbox=${
      currentStore.coordinates.lng - 0.01
    }%2C${
      currentStore.coordinates.lat - 0.01
    }%2C${
      currentStore.coordinates.lng + 0.01
    }%2C${
      currentStore.coordinates.lat + 0.01
    }&layer=mapnik&marker=${currentStore.coordinates.lat}%2C${currentStore.coordinates.lng}`;
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[300px] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1613665813446-82a78c468a1d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            alt="Store Locations"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4">
          <h1 className="text-5xl font-bold text-white mb-4">Onde encontrar chip UaiSat</h1>
          <p className="text-xl text-white/90">
            Encontre o ponto de venda mais próximo de você
          </p>
        </div>
      </section>

      {/* Store Locator Section */}
      <section className="py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Store List */}
            <div className="lg:col-span-1">
              <div className="bg-white rounded-2xl shadow-sm p-6">
                <div className="mb-6">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Buscar por cidade ou endereço"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    />
                    <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                  </div>
                </div>

                <div className="space-y-4">
                  {filteredStores.map(store => (
                    <button
                      key={store.id}
                      onClick={() => handleStoreSelect(store)}
                      className={`w-full text-left p-4 rounded-xl transition-all duration-200 ${
                        selectedStore?.id === store.id
                          ? 'bg-uai-light border-2 border-uai-teal'
                          : 'bg-gray-50 hover:bg-gray-100'
                      }`}
                    >
                      <h3 className="font-semibold text-lg mb-2">{store.name}</h3>
                      <div className="flex items-start text-gray-600">
                        <MapPin className="w-5 h-5 mr-2 flex-shrink-0 mt-1" />
                        <p>{store.address}, {store.city} - {store.state}</p>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Map and Store Details */}
            <div className="lg:col-span-2">
              {/* Map */}
              <div className="bg-white rounded-2xl shadow-sm overflow-hidden mb-8">
                <div className="aspect-w-16 aspect-h-9">
                  <iframe
                    src={getMapUrl(selectedStore)}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    className="rounded-2xl"
                    title="Store Location Map"
                  />
                </div>
              </div>

              {/* Store Details */}
              {selectedStore && (
                <div className="bg-white rounded-2xl shadow-sm p-6">
                  <h2 className="text-2xl font-bold mb-6">{selectedStore.name}</h2>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <div className="flex items-start">
                        <MapPin className="w-5 h-5 text-uai-teal mr-3 mt-1" />
                        <div>
                          <p className="font-medium">Endereço</p>
                          <p className="text-gray-600">{selectedStore.address}</p>
                          <p className="text-gray-600">{selectedStore.city} - {selectedStore.state}</p>
                        </div>
                      </div>

                      {selectedStore.phone && (
                        <div className="flex items-start">
                          <Phone className="w-5 h-5 text-uai-teal mr-3 mt-1" />
                          <div>
                            <p className="font-medium">Telefone</p>
                            <p className="text-gray-600">{selectedStore.phone}</p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="space-y-4">
                      {selectedStore.hours && (
                        <div className="flex items-start">
                          <Clock className="w-5 h-5 text-uai-teal mr-3 mt-1" />
                          <div>
                            <p className="font-medium">Horário de Funcionamento</p>
                            {selectedStore.hours.split('\n').map((line, index) => (
                              <p key={index} className="text-gray-600">{line}</p>
                            ))}
                          </div>
                        </div>
                      )}

                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${selectedStore.coordinates.lat},${selectedStore.coordinates.lng}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-uai-teal hover:text-uai-dark font-medium transition-colors group mt-4"
                      >
                        <span>Como chegar</span>
                        <ArrowRight className="w-5 h-5 ml-2 transform transition-transform duration-300 group-hover:translate-x-1" />
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Additional Info */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">Não encontrou uma loja próxima?</h2>
          <p className="text-xl text-gray-600 mb-8">
            Você também pode solicitar seu chip online e receber em casa
          </p>
          <a
            href="/solicitar-chip"
            className="inline-flex items-center bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-xl transition-all duration-300 group"
          >
            <span>Solicitar chip online</span>
            <ArrowRight className="w-5 h-5 ml-2 transform transition-transform duration-300 group-hover:translate-x-1" />
          </a>
        </div>
      </section>
    </div>
  );
};

export default Stores;