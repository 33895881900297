import React, { useState } from 'react';
import { Smartphone, QrCode, Wifi, AlertCircle, CheckCircle, HelpCircle, KeyRound, Keyboard } from 'lucide-react';

const EsimGuide: React.FC = () => {
  const [selectedMethod, setSelectedMethod] = useState<'qr' | 'manual' | null>(null);
  const serverAddress = "sm-v4-064-a-gtm.pr.go-esim.com";
  
  const devices = [
    {
      name: 'iPhone',
      models: [
        'iPhone XS, XS Max, XR',
        'iPhone 11, 11 Pro, 11 Pro Max',
        'iPhone 12, 12 mini, 12 Pro, 12 Pro Max',
        'iPhone 13, 13 mini, 13 Pro, 13 Pro Max',
        'iPhone 14, 14 Plus, 14 Pro, 14 Pro Max',
        'iPhone 15, 15 Plus, 15 Pro, 15 Pro Max'
      ],
      steps: {
        qr: [
          'Vá em Ajustes > Celular',
          'Toque em "Adicionar Plano Celular"',
          'Escaneie o QR Code recebido',
          'Siga as instruções na tela para completar a ativação'
        ],
        manual: [
          'Vá em Ajustes > Celular',
          'Toque em "Adicionar Plano Celular"',
          'Toque em "Digite Detalhes Manualmente"',
          `Digite o SM-DP+ endereço: ${serverAddress}`,
          'Digite o código de ativação recebido',
          'Confirme a ativação'
        ]
      }
    },
    {
      name: 'Samsung Galaxy',
      models: [
        'Galaxy S20, S20+, S20 Ultra',
        'Galaxy S21, S21+, S21 Ultra',
        'Galaxy S22, S22+, S22 Ultra',
        'Galaxy S23, S23+, S23 Ultra',
        'Galaxy S24, S24+, S24 Ultra',
        'Galaxy Note 20, Note 20 Ultra',
        'Galaxy Z Fold 2, 3, 4, 5',
        'Galaxy Z Flip, Flip 3, 4, 5'
      ],
      steps: {
        qr: [
          'Vá em Configurações > Conexões > Gerenciar cartões SIM',
          'Toque em "Adicionar plano móvel"',
          'Escaneie o QR Code recebido',
          'Siga as instruções na tela para completar a ativação'
        ],
        manual: [
          'Vá em Configurações > Conexões > Gerenciar cartões SIM',
          'Toque em "Adicionar plano móvel"',
          'Selecione "Adicionar usando código de ativação"',
          `Digite o endereço do servidor: ${serverAddress}`,
          'Insira o código de ativação recebido',
          'Toque em "Adicionar" e confirme'
        ]
      }
    },
    {
      name: 'Google Pixel',
      models: [
        'Pixel 3, 3 XL',
        'Pixel 4, 4 XL',
        'Pixel 4a, 4a 5G',
        'Pixel 5, 5a',
        'Pixel 6, 6 Pro',
        'Pixel 6a',
        'Pixel 7, 7 Pro',
        'Pixel 7a',
        'Pixel 8, 8 Pro'
      ],
      steps: {
        qr: [
          'Vá em Configurações > Rede e Internet',
          'Toque em "+" ao lado de Celular',
          'Escaneie o QR Code recebido',
          'Siga as instruções na tela para completar a ativação'
        ],
        manual: [
          'Vá em Configurações > Rede e Internet',
          'Toque em "+" ao lado de Celular',
          'Selecione "Não tem um código QR?"',
          `Digite o endereço do servidor: ${serverAddress}`,
          'Insira o código de ativação fornecido',
          'Confirme a instalação'
        ]
      }
    },
    {
      name: 'Motorola',
      models: [
        'Edge 30 Ultra',
        'Edge 30 Pro',
        'Edge 30 Fusion',
        'Edge 40 Pro',
        'Edge 40',
        'Edge 40 Neo'
      ],
      steps: {
        qr: [
          'Vá em Configurações > Rede e Internet > SIM',
          'Toque em "Adicionar operadora"',
          'Escaneie o QR Code recebido',
          'Siga as instruções na tela'
        ],
        manual: [
          'Vá em Configurações > Rede e Internet > SIM',
          'Toque em "Adicionar operadora"',
          'Selecione "Inserir manualmente"',
          `Digite o endereço do servidor: ${serverAddress}`,
          'Insira o código de ativação',
          'Confirme a instalação'
        ]
      }
    },
    {
      name: 'Xiaomi',
      models: [
        'Mi 11, 11 Pro, 11 Ultra',
        'Mi 12, 12 Pro, 12 Ultra',
        'Mi 13, 13 Pro',
        'Redmi Note 11 Pro+ 5G',
        'Redmi Note 12 Pro+ 5G',
        'POCO F4 GT',
        'POCO F5 Pro'
      ],
      steps: {
        qr: [
          'Vá em Configurações > Cartões SIM e redes móveis',
          'Toque em "Adicionar eSIM"',
          'Escaneie o QR Code recebido',
          'Siga as instruções para ativar'
        ],
        manual: [
          'Vá em Configurações > Cartões SIM e redes móveis',
          'Toque em "Adicionar eSIM"',
          'Selecione "Inserir manualmente"',
          `Digite o endereço do servidor: ${serverAddress}`,
          'Insira o código de ativação',
          'Confirme a instalação'
        ]
      }
    }
  ];

  const troubleshooting = [
    {
      problem: 'QR Code não funciona',
      solution: 'Verifique se o código não está danificado ou borrado. Se o problema persistir, tente a ativação manual ou entre em contato com nosso suporte.'
    },
    {
      problem: 'Erro de ativação',
      solution: 'Certifique-se de que seu dispositivo está conectado à internet e tente novamente. Se o erro continuar, reinicie o dispositivo.'
    },
    {
      problem: 'Dispositivo não compatível',
      solution: 'Verifique se seu aparelho suporta eSIM. Consulte o manual do fabricante ou nossa lista de dispositivos compatíveis.'
    },
    {
      problem: 'Código de ativação inválido',
      solution: 'Verifique se digitou o código corretamente, respeitando maiúsculas e minúsculas. Se o problema persistir, solicite um novo código.'
    }
  ];

  return (
    <div className="py-16 px-4">
      <div className="max-w-4xl mx-auto">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Como ativar seu eSIM UaiSat</h1>
          <p className="text-xl text-gray-600">
            Siga o passo a passo para ativar seu eSIM em poucos minutos
          </p>
        </div>

        {/* What is eSIM */}
        <section className="bg-white rounded-2xl shadow-sm p-8 mb-8">
          <div className="flex items-start">
            <Smartphone className="w-8 h-8 text-uai-teal mr-4 flex-shrink-0" />
            <div>
              <h2 className="text-2xl font-bold mb-4">O que é eSIM?</h2>
              <p className="text-gray-600 mb-4">
                O eSIM é um chip digital que substitui o chip físico tradicional. Com ele, você pode ativar sua linha UaiSat diretamente no seu smartphone, sem precisar inserir um chip físico.
              </p>
              <div className="bg-uai-light rounded-xl p-4 flex items-start">
                <AlertCircle className="w-5 h-5 text-uai-teal mr-2 flex-shrink-0 mt-0.5" />
                <p className="text-sm">
                  Antes de começar, certifique-se de que seu dispositivo é compatível com eSIM e que você recebeu o QR Code ou código de ativação por email.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Activation Methods */}
        <section className="bg-white rounded-2xl shadow-sm p-8 mb-8">
          <h2 className="text-2xl font-bold mb-6">Escolha o método de ativação</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <button
              onClick={() => setSelectedMethod('qr')}
              className={`text-left border rounded-xl p-6 transition-all duration-200 ${
                selectedMethod === 'qr'
                  ? 'border-uai-teal bg-uai-light'
                  : 'border-gray-100 hover:border-gray-200 hover:shadow-md'
              }`}
            >
              <div className="flex items-center mb-4">
                <QrCode className="w-8 h-8 text-uai-teal mr-3" />
                <h3 className="text-xl font-bold">Via QR Code</h3>
              </div>
              <p className="text-gray-600">
                Método mais simples e recomendado. Basta escanear o QR Code recebido por email usando a câmera do seu dispositivo.
              </p>
            </button>

            <button
              onClick={() => setSelectedMethod('manual')}
              className={`text-left border rounded-xl p-6 transition-all duration-200 ${
                selectedMethod === 'manual'
                  ? 'border-uai-teal bg-uai-light'
                  : 'border-gray-100 hover:border-gray-200 hover:shadow-md'
              }`}
            >
              <div className="flex items-center mb-4">
                <Keyboard className="w-8 h-8 text-uai-teal mr-3" />
                <h3 className="text-xl font-bold">Ativação Manual</h3>
              </div>
              <p className="text-gray-600">
                Alternativa quando o QR Code não funciona. Use o código de ativação e o endereço do servidor fornecidos.
              </p>
            </button>
          </div>
        </section>

        {/* Device-specific Instructions */}
        {selectedMethod && (
          <section className="bg-white rounded-2xl shadow-sm p-8 mb-8">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
              <Smartphone className="w-8 h-8 text-uai-teal mr-2" />
              Instruções por dispositivo
            </h2>
            
            <div className="grid gap-8">
              {devices.map((device, index) => (
                <div 
                  key={index}
                  className="border border-gray-100 rounded-xl p-6 hover:shadow-md transition-shadow duration-300"
                >
                  <h3 className="text-xl font-bold mb-2">{device.name}</h3>
                  <div className="mb-4">
                    <p className="text-gray-600 font-medium mb-2">Modelos compatíveis:</p>
                    <ul className="list-disc pl-5 text-gray-600 space-y-1">
                      {device.models.map((model, modelIndex) => (
                        <li key={modelIndex}>{model}</li>
                      ))}
                    </ul>
                  </div>

                  <div className="mt-6">
                    <h4 className="font-bold text-lg mb-3 flex items-center">
                      {selectedMethod === 'qr' ? (
                        <>
                          <QrCode className="w-5 h-5 text-uai-teal mr-2" />
                          Via QR Code
                        </>
                      ) : (
                        <>
                          <KeyRound className="w-5 h-5 text-uai-teal mr-2" />
                          Ativação Manual
                        </>
                      )}
                    </h4>
                    <ol className="space-y-3">
                      {device.steps[selectedMethod].map((step, stepIndex) => (
                        <li key={stepIndex} className="flex items-start">
                          <span className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                            {stepIndex + 1}
                          </span>
                          <span>{step}</span>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}

        {/* Tips */}
        <section className="bg-white rounded-2xl shadow-sm p-8 mb-8">
          <h2 className="text-2xl font-bold mb-6 flex items-center">
            <CheckCircle className="w-8 h-8 text-uai-teal mr-2" />
            Dicas importantes
          </h2>
          
          <div className="space-y-4">
            <div className="flex items-start">
              <Wifi className="w-5 h-5 text-uai-teal mr-3 mt-1" />
              <p>
                Mantenha seu dispositivo conectado a uma rede Wi-Fi estável durante o processo de ativação.
              </p>
            </div>
            <div className="flex items-start">
              <Smartphone className="w-5 h-5 text-uai-teal mr-3 mt-1" />
              <p>
                Certifique-se de que seu dispositivo está com a bateria carregada e o sistema operacional atualizado.
              </p>
            </div>
            <div className="flex items-start">
              <QrCode className="w-5 h-5 text-uai-teal mr-3 mt-1" />
              <p>
                Guarde o QR Code e o código de ativação em um local seguro, você pode precisar deles novamente no futuro.
              </p>
            </div>
            <div className="flex items-start">
              <KeyRound className="w-5 h-5 text-uai-teal mr-3 mt-1" />
              <p>
                Ao digitar o código de ativação manualmente, preste atenção às letras maiúsculas e minúsculas.
              </p>
            </div>
          </div>
        </section>

        {/* Troubleshooting */}
        <section className="bg-white rounded-2xl shadow-sm p-8">
          <h2 className="text-2xl font-bold mb-6 flex items-center">
            <HelpCircle className="w-8 h-8 text-uai-teal mr-2" />
            Solução de problemas
          </h2>
          
          <div className="space-y-6">
            {troubleshooting.map((item, index) => (
              <div key={index} className="border-b border-gray-100 last:border-0 pb-6 last:pb-0">
                <h3 className="font-bold mb-2">{item.problem}</h3>
                <p className="text-gray-600">{item.solution}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Support */}
        <div className="mt-12 bg-gray-50 rounded-2xl p-8 text-center">
          <h2 className="text-xl font-bold mb-4">Precisa de ajuda?</h2>
          <p className="text-gray-600 mb-6">
            Nossa equipe de suporte está disponível 24/7 para ajudar você com a ativação do seu eSIM.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a
              href="tel:*288"
              className="bg-uai-teal hover:bg-uai-dark text-white px-6 py-3 rounded-xl flex items-center justify-center transition-colors duration-200"
            >
              <Smartphone className="w-5 h-5 mr-2" />
              <span>Ligar para *288</span>
            </a>
            <a
              href="mailto:suporte@uaimovel.com.br"
              className="border border-uai-teal text-uai-teal hover:bg-uai-teal hover:text-white px-6 py-3 rounded-xl flex items-center justify-center transition-colors duration-200"
            >
              <HelpCircle className="w-5 h-5 mr-2" />
              <span>Enviar email</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsimGuide;