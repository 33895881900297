import React, { useEffect } from 'react';
import { Phone, CheckCircle, XCircle, ExternalLink } from 'lucide-react';
import { useRechargeStore } from '../../lib/store';

const History: React.FC = () => {
  const { history, loadHistory, subscribeToChanges } = useRechargeStore();

  useEffect(() => {
    // Load initial history
    loadHistory();

    // Subscribe to real-time updates
    const unsubscribe = subscribeToChanges();

    // Cleanup subscription
    return () => {
      unsubscribe();
    };
  }, []);

  const formatDateTime = (date: string) => {
    return new Date(date).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Histórico de Recargas</h1>

      {history.length > 0 ? (
        <div className="bg-white rounded-2xl shadow-sm divide-y">
          {history.map((item) => (
            <div key={item.id} className="p-4">
              <div className="flex items-center">
                <div className="w-10 h-10 rounded-full bg-uai-light flex items-center justify-center mr-4">
                  <Phone className="w-5 h-5 text-uai-teal" />
                </div>
                <div className="flex-1">
                  <div className="flex items-center">
                    <p className="font-medium">{item.phone}</p>
                    {item.status === 'success' ? (
                      <CheckCircle className="w-5 h-5 text-green-500 ml-2" />
                    ) : item.status === 'failed' ? (
                      <XCircle className="w-5 h-5 text-red-500 ml-2" />
                    ) : (
                      <div className="ml-2 h-5 w-5 rounded-full border-2 border-yellow-500 border-t-transparent animate-spin" />
                    )}
                  </div>
                  <p className="text-sm text-gray-500">
                    R$ {item.amount.toFixed(2)} • {formatDateTime(item.created_at)}
                  </p>
                  <div className="mt-2 text-sm">
                    <p className="text-gray-600">{item.plan_data} • {item.plan_validity}</p>
                  </div>
                  {item.payment_url && (
                    <a
                      href={item.payment_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-2 inline-flex items-center text-uai-teal hover:text-uai-dark text-sm"
                    >
                      <ExternalLink className="w-4 h-4 mr-1" />
                      Ver comprovante
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12 text-gray-500">
          <p>Nenhuma recarga encontrada</p>
        </div>
      )}
    </div>
  );
};

export default History;