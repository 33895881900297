import React from 'react';
import { HelpCircle, ArrowLeft, Phone, Mail, MessageSquare, FileText } from 'lucide-react';

const Support: React.FC = () => {
  const supportOptions = [
    {
      icon: Phone,
      title: 'Central de Atendimento',
      description: '*288 ou 10543',
      action: 'Ligar agora',
      href: 'tel:*288'
    },
    {
      icon: Mail,
      title: 'Email',
      description: 'contato@uaimovel.com.br',
      action: 'Enviar email',
      href: 'mailto:contato@uaimovel.com.br'
    },
    {
      icon: MessageSquare,
      title: 'Chat',
      description: 'Atendimento online',
      action: 'Iniciar chat',
      onClick: () => {/* Implement chat */ }
    },
    {
      icon: FileText,
      title: 'FAQ',
      description: 'Perguntas frequentes',
      action: 'Ver FAQ',
      href: '/faq'
    }
  ];

  return (
    <div>
      <div className="flex items-center mb-6">
        <button 
          onClick={() => window.history.back()}
          className="mr-4 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="w-6 h-6" />
        </button>
        <h1 className="text-2xl font-bold">Ajuda e Suporte</h1>
      </div>

      <div className="space-y-6">
        {/* Support Options */}
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <div className="flex items-center mb-6">
            <HelpCircle className="w-6 h-6 text-uai-teal mr-2" />
            <h2 className="text-lg font-semibold">Como podemos ajudar?</h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {supportOptions.map((option, index) => {
              const Icon = option.icon;
              return (
                <div
                  key={index}
                  className="p-4 rounded-xl border border-gray-200 hover:border-uai-teal transition-colors"
                >
                  <div className="flex items-start">
                    <div className="w-10 h-10 rounded-full bg-uai-light flex items-center justify-center mr-4">
                      <Icon className="w-5 h-5 text-uai-teal" />
                    </div>
                    <div className="flex-1">
                      <h3 className="font-medium">{option.title}</h3>
                      <p className="text-sm text-gray-500 mb-4">{option.description}</p>
                      {'href' in option ? (
                        <a
                          href={option.href}
                          className="text-uai-teal hover:text-uai-dark font-medium text-sm"
                        >
                          {option.action}
                        </a>
                      ) : (
                        <button
                          onClick={option.onClick}
                          className="text-uai-teal hover:text-uai-dark font-medium text-sm"
                        >
                          {option.action}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Common Issues */}
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-6">Problemas comuns</h2>
          <div className="space-y-4">
            {[
              'Como fazer uma recarga?',
              'Não consigo acessar minha conta',
              'Problemas com pagamento',
              'Como alterar meu plano?'
            ].map((issue, index) => (
              <button
                key={index}
                className="w-full text-left p-4 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <p className="font-medium">{issue}</p>
                <p className="text-sm text-gray-500">Ver solução</p>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;