import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Phone, 
  Building2, 
  Info, 
  MapPin, 
  Menu,
  X,
  MessageSquare,
  User
} from 'lucide-react';
import { LINKS } from '../lib/config';

interface HeaderProps {
  city: string;
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (open: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ city, mobileMenuOpen, setMobileMenuOpen }) => {
  const location = useLocation();
  
  return (
    <header className="bg-white shadow-md sticky top-0 z-50">
      <div className="max-w-7xl mx-auto">
        {/* Top bar */}
        <div className="py-2 px-4">
          <div className="flex items-center justify-between text-sm text-gray-600">
            <div className="flex items-center">
              <MapPin className="w-4 h-4 text-uai-teal mr-1" />
              <span>Você está em: <strong className="text-gray-900">{city}</strong></span>
            </div>
          </div>
        </div>
        
        {/* Main header */}
        <div className="py-4 px-4 flex justify-between items-center">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/">
              <img 
                src="https://cloud.uaisat.app.br/03/Logo-Grupo-UaiSat.png" 
                alt="UaiSat Logo" 
                className="h-10"
              />
            </Link>
          </div>
          
          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            <Link 
              to="/" 
              className={`text-gray-700 hover:text-uai-teal font-medium flex items-center ${
                location.pathname === '/' ? 'text-uai-teal' : ''
              }`}
            >
              <Phone className="w-5 h-5 mr-1" />
              <span>Você</span>
            </Link>
            <Link 
              to="/empresa" 
              className={`text-gray-700 hover:text-uai-teal font-medium flex items-center ${
                location.pathname === '/empresa' ? 'text-uai-teal' : ''
              }`}
            >
              <Building2 className="w-5 h-5 mr-1" />
              <span>Empresa</span>
            </Link>
            <Link 
              to="/sobre" 
              className={`text-gray-700 hover:text-uai-teal font-medium flex items-center ${
                location.pathname === '/sobre' ? 'text-uai-teal' : ''
              }`}
            >
              <Info className="w-5 h-5 mr-1" />
              <span>Sobre nós</span>
            </Link>
            <Link 
              to="/contato" 
              className={`text-gray-700 hover:text-uai-teal font-medium flex items-center ${
                location.pathname === '/contato' ? 'text-uai-teal' : ''
              }`}
            >
              <MessageSquare className="w-5 h-5 mr-1" />
              <span>Contato</span>
            </Link>
            <a 
              href="https://app.uaisat.app.br"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-uai-teal hover:bg-uai-dark text-white font-medium py-2 px-4 rounded-md transition-colors flex items-center"
            >
              <User className="w-4 h-4 mr-2" />
              <span>Central do Cliente</span>
            </a>
          </nav>
          
          {/* Mobile menu button */}
          <button 
            className="md:hidden text-gray-700"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            aria-label="Toggle menu"
          >
            {mobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>
        </div>
        
        {/* Mobile Navigation */}
        {mobileMenuOpen && (
          <nav className="md:hidden bg-white py-4 px-4 shadow-lg">
            <div className="flex flex-col space-y-4">
              <Link 
                to="/" 
                className={`text-gray-700 hover:text-uai-teal font-medium flex items-center ${
                  location.pathname === '/' ? 'text-uai-teal' : ''
                }`}
                onClick={() => setMobileMenuOpen(false)}
              >
                <Phone className="w-5 h-5 mr-2" />
                <span>Você</span>
              </Link>
              <Link 
                to="/empresa" 
                className={`text-gray-700 hover:text-uai-teal font-medium flex items-center ${
                  location.pathname === '/empresa' ? 'text-uai-teal' : ''
                }`}
                onClick={() => setMobileMenuOpen(false)}
              >
                <Building2 className="w-5 h-5 mr-2" />
                <span>Empresa</span>
              </Link>
              <Link 
                to="/sobre" 
                className={`text-gray-700 hover:text-uai-teal font-medium flex items-center ${
                  location.pathname === '/sobre' ? 'text-uai-teal' : ''
                }`}
                onClick={() => setMobileMenuOpen(false)}
              >
                <Info className="w-5 h-5 mr-2" />
                <span>Sobre nós</span>
              </Link>
              <Link 
                to="/contato" 
                className={`text-gray-700 hover:text-uai-teal font-medium flex items-center ${
                  location.pathname === '/contato' ? 'text-uai-teal' : ''
                }`}
                onClick={() => setMobileMenuOpen(false)}
              >
                <MessageSquare className="w-5 h-5 mr-2" />
                <span>Contato</span>
              </Link>
              <a 
                href="https://app.uaisat.app.br"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-uai-teal hover:bg-uai-dark text-white font-medium py-3 px-4 rounded-md transition-colors flex items-center justify-center"
                onClick={() => setMobileMenuOpen(false)}
              >
                <User className="w-4 h-4 mr-2" />
                <span>Central do Cliente</span>
              </a>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;