import React, { useState } from 'react';
import Banner from '../components/Banner';
import PlanCard from '../components/PlanCard';
import CoverageSearch from '../components/CoverageSearch';
import Benefits from '../components/Benefits';
import AppDownload from '../components/AppDownload';
import Testimonials from '../components/Testimonials';
import { plans } from '../lib/data';

const Home: React.FC = () => {
  const [activeTab, setActiveTab] = useState('controle');

  const controlePlans = plans.filter(plan => plan.type === 'controle' && plan.active);
  const prePagoPlans = plans.filter(plan => plan.type === 'prepago' && plan.active);

  return (
    <>
      <Banner />

      <section id="planos" className="py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-4">Nossos Planos</h2>
          <p className="text-xl text-gray-600 text-center max-w-2xl mx-auto mb-8">
            Escolha o plano ideal para você com os melhores benefícios e cobertura nacional
          </p>
          
          <div className="flex justify-center mb-8">
            <div className="inline-flex rounded-xl shadow-sm" role="group">
              <button
                type="button"
                className={`px-8 py-4 text-lg font-medium rounded-l-xl transition-all duration-300 ${
                  activeTab === 'controle'
                    ? 'bg-uai-teal text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
                }`}
                onClick={() => setActiveTab('controle')}
              >
                Controle
              </button>
              <button
                type="button"
                className={`px-8 py-4 text-lg font-medium rounded-r-xl transition-all duration-300 ${
                  activeTab === 'prepago'
                    ? 'bg-uai-teal text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
                }`}
                onClick={() => setActiveTab('prepago')}
              >
                Pré-pago
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {activeTab === 'controle'
              ? controlePlans.map((plan) => (
                  <PlanCard key={plan.id} plan={plan} type="controle" />
                ))
              : prePagoPlans.map((plan) => (
                  <PlanCard key={plan.id} plan={plan} type="prepago" />
                ))}
          </div>
        </div>
      </section>

      <section id="cobertura" className="py-16 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">Cobertura UaiSat</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Verifique a disponibilidade e qualidade do sinal na sua região
            </p>
          </div>
          <CoverageSearch />
        </div>
      </section>

      <Benefits />
      <AppDownload />
      <Testimonials />
    </>
  );
}

export default Home;