import React, { useState } from 'react';
import { Store, User, MapPin, Phone, Mail, Send, Loader2, CheckCircle, DollarSign, Users, Package, BadgeCheck } from 'lucide-react';
import { IMaskInput } from 'react-imask';
import toast from 'react-hot-toast';

const BecomeReseller: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    cpf: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    experience: '',
    sales_channel: '',
    has_experience: 'false',
    current_occupation: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Here you would integrate with your backend API
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulating API call
      
      toast.success('Solicitação enviada com sucesso! Em breve entraremos em contato.');
      
      // Reset form
      setFormData({
        name: '',
        cpf: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        experience: '',
        sales_channel: '',
        has_experience: 'false',
        current_occupation: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Erro ao enviar solicitação. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const benefits = [
    {
      icon: <DollarSign className="w-12 h-12 text-uai-teal" />,
      title: 'Renda Extra',
      description: 'Ganhe comissões atrativas em cada chip vendido e recarga realizada'
    },
    {
      icon: <Package className="w-12 h-12 text-uai-teal" />,
      title: 'Sem Investimento',
      description: 'Comece sem precisar investir em estoque ou estrutura física'
    },
    {
      icon: <Users className="w-12 h-12 text-uai-teal" />,
      title: 'Suporte Completo',
      description: 'Treinamento e material de apoio para impulsionar suas vendas'
    },
    {
      icon: <BadgeCheck className="w-12 h-12 text-uai-teal" />,
      title: 'Credibilidade',
      description: 'Seja um vendedor oficial de uma das operadoras que mais crescem no Brasil'
    }
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[400px] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2532&q=80"
            alt="Become a Reseller"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4">
          <div className="max-w-2xl">
            <h1 className="text-5xl font-bold text-white mb-6">Seja um Vendedor UaiSat</h1>
            <p className="text-xl text-white/90">
              Comece agora mesmo a ganhar dinheiro vendendo chips e recargas UaiSat
            </p>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Por que ser um vendedor UaiSat?</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div 
                key={index}
                className="bg-white p-6 rounded-xl shadow-card hover:shadow-xl transition-all duration-300 group hover:-translate-y-1"
              >
                <div className="mb-6 transform group-hover:scale-110 transition-transform duration-300">
                  {benefit.icon}
                </div>
                <h3 className="text-xl font-bold mb-3">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Registration Form */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="max-w-3xl mx-auto">
          <div className="bg-white rounded-2xl shadow-xl p-8">
            <h2 className="text-2xl font-bold mb-6">Cadastre-se como Vendedor</h2>
            
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Personal Information */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold flex items-center">
                  <User className="w-5 h-5 text-uai-teal mr-2" />
                  Dados Pessoais
                </h3>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Nome Completo
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    CPF
                  </label>
                  <IMaskInput
                    mask="000.000.000-00"
                    value={formData.cpf}
                    unmask={true}
                    onAccept={(value) => setFormData({ ...formData, cpf: value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    required
                  />
                </div>
              </div>

              {/* Contact Information */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold flex items-center">
                  <Phone className="w-5 h-5 text-uai-teal mr-2" />
                  Contato
                </h3>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Telefone
                    </label>
                    <IMaskInput
                      mask="(00) 00000-0000"
                      value={formData.phone}
                      unmask={true}
                      onAccept={(value) => setFormData({ ...formData, phone: value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Address */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold flex items-center">
                  <MapPin className="w-5 h-5 text-uai-teal mr-2" />
                  Endereço
                </h3>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Endereço Completo
                  </label>
                  <input
                    type="text"
                    value={formData.address}
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    required
                  />
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Cidade
                    </label>
                    <input
                      type="text"
                      value={formData.city}
                      onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Estado
                    </label>
                    <select
                      value={formData.state}
                      onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                      required
                    >
                      <option value="">UF</option>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      CEP
                    </label>
                    <IMaskInput
                      mask="00000-000"
                      value={formData.zip}
                      unmask={true}
                      onAccept={(value) => setFormData({ ...formData, zip: value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Sales Information */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold flex items-center">
                  <Store className="w-5 h-5 text-uai-teal mr-2" />
                  Informações de Vendas
                </h3>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Como pretende vender?
                  </label>
                  <select
                    value={formData.sales_channel}
                    onChange={(e) => setFormData({ ...formData, sales_channel: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    required
                  >
                    <option value="">Selecione...</option>
                    <option value="porta_porta">Porta a porta</option>
                    <option value="indicacoes">Por indicações</option>
                    <option value="redes_sociais">Redes sociais</option>
                    <option value="whatsapp">WhatsApp</option>
                    <option value="eventos">Eventos e feiras</option>
                    <option value="outro">Outro</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Já tem experiência com vendas?
                  </label>
                  <div className="flex space-x-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        value="true"
                        checked={formData.has_experience === 'true'}
                        onChange={(e) => setFormData({ ...formData, has_experience: e.target.value })}
                        className="mr-2"
                      />
                      Sim
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        value="false"
                        checked={formData.has_experience === 'false'}
                        onChange={(e) => setFormData({ ...formData, has_experience: e.target.value })}
                        className="mr-2"
                      />
                      Não
                    </label>
                  </div>
                </div>

                {formData.has_experience === 'true' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Conte-nos sobre sua experiência
                    </label>
                    <textarea
                      value={formData.experience}
                      onChange={(e) => setFormData({ ...formData, experience: e.target.value })}
                      rows={3}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                      required
                    ></textarea>
                  </div>
                )}

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Ocupação atual
                  </label>
                  <textarea
                    value={formData.current_occupation}
                    onChange={(e) => setFormData({ ...formData, current_occupation: e.target.value })}
                    rows={3}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                    placeholder="Conte-nos um pouco sobre sua ocupação atual"
                    required
                  ></textarea>
                </div>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 flex items-center justify-center group disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                    Enviando...
                  </>
                ) : (
                  <>
                    <Send className="w-5 h-5 mr-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                    Enviar cadastro
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BecomeReseller;