import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard,
  Users,
  CreditCard,
  Settings,
  LogOut,
  FileText,
  BarChart2,
  Bell,
  Store
} from 'lucide-react';
import { useAuth } from '../lib/auth';

const AdminLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const navigation = [
    { name: 'Dashboard', href: '/app/admin', icon: LayoutDashboard },
    { name: 'Usuários', href: '/app/admin/users', icon: Users },
    { name: 'Recargas', href: '/app/admin/recharges', icon: CreditCard },
    { name: 'Relatórios', href: '/app/admin/reports', icon: FileText },
    { name: 'Estatísticas', href: '/app/admin/stats', icon: BarChart2 },
    { name: 'Notificações', href: '/app/admin/notifications', icon: Bell },
    { name: 'Vendedores', href: '/app/admin/vendors', icon: Store },
    { name: 'Configurações', href: '/app/admin/settings', icon: Settings }
  ];

  const handleLogout = () => {
    logout();
    navigate('/app/login');
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      <aside className="w-64 bg-white shadow-sm border-r border-gray-200 fixed h-screen">
        <div className="h-16 flex items-center justify-center border-b">
          <img 
            src="https://cloud.uaisat.app.br/03/Logo-Grupo-UaiSat.png" 
            alt="UaiSat Logo" 
            className="h-8"
          />
        </div>
        
        <nav className="mt-6 px-4">
          <div className="space-y-1">
            {navigation.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.href;
              return (
                <button
                  key={item.name}
                  onClick={() => navigate(item.href)}
                  className={`flex items-center w-full px-4 py-3 text-sm font-medium rounded-lg transition-colors ${
                    isActive
                      ? 'bg-uai-light text-uai-teal'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-uai-teal'
                  }`}
                >
                  <Icon className="w-5 h-5 mr-3" />
                  {item.name}
                </button>
              );
            })}
          </div>
        </nav>

        <div className="absolute bottom-0 w-full p-4 border-t">
          <button
            onClick={handleLogout}
            className="flex items-center w-full px-4 py-2 text-sm font-medium text-gray-600 hover:text-uai-teal hover:bg-gray-50 rounded-lg transition-colors"
          >
            <LogOut className="w-5 h-5 mr-3" />
            Sair
          </button>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 ml-64 p-8">
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;