import React from 'react';
import { Star, User, Quote } from 'lucide-react';

interface Testimonial {
  name: string;
  rating: number;
  comment: string;
  city: string;
}

interface TestimonialCardProps {
  testimonial: Testimonial;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ testimonial }) => {
  return (
    <div className="bg-white p-8 rounded-2xl shadow-card hover:shadow-2xl transition-all duration-500 transform hover:-translate-y-2 relative">
      <div className="absolute -top-4 -right-4 bg-uai-teal text-white p-3 rounded-full shadow-lg">
        <Quote className="w-5 h-5" />
      </div>
      
      <div className="flex items-center mb-6">
        {[...Array(5)].map((_, i) => (
          <Star
            key={i}
            className={`w-5 h-5 ${
              i < testimonial.rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-200'
            }`}
          />
        ))}
      </div>
      
      <p className="text-gray-700 mb-8 text-lg leading-relaxed italic">"{testimonial.comment}"</p>
      
      <div className="flex items-center">
        <div className="bg-gradient-to-br from-uai-teal to-uai-dark p-3 rounded-full mr-4">
          <User className="w-6 h-6 text-white" />
        </div>
        <div>
          <p className="font-bold text-lg">{testimonial.name}</p>
          <p className="text-gray-500">{testimonial.city}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;