import React from 'react';
import { BarChart2, TrendingUp, Download } from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Stats: React.FC = () => {
  // Sample data for charts
  const last7Days = [...Array(7)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() - i);
    return d.toLocaleDateString('pt-BR', { weekday: 'short' });
  }).reverse();

  const rechargeData = {
    labels: last7Days,
    datasets: [
      {
        label: 'Recargas',
        data: last7Days.map(() => Math.floor(Math.random() * 50)),
        borderColor: '#00a9a7',
        backgroundColor: 'rgba(0, 169, 167, 0.1)',
        fill: true,
        tension: 0.4
      }
    ]
  };

  const valueData = {
    labels: last7Days,
    datasets: [
      {
        label: 'Valor (R$)',
        data: last7Days.map(() => Math.floor(Math.random() * 1000) + 500),
        backgroundColor: '#00a9a7',
        borderRadius: 8
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Estatísticas</h1>
        <button className="bg-uai-teal hover:bg-uai-dark text-white px-4 py-2 rounded-lg flex items-center">
          <Download className="w-5 h-5 mr-2" />
          Exportar
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Recharges Line Chart */}
        <div className="bg-white p-6 rounded-2xl shadow-sm">
          <div className="flex items-center mb-6">
            <BarChart2 className="w-6 h-6 text-uai-teal mr-2" />
            <h2 className="text-lg font-semibold">Recargas por Dia</h2>
          </div>
          <Line data={rechargeData} options={chartOptions} />
        </div>

        {/* Values Bar Chart */}
        <div className="bg-white p-6 rounded-2xl shadow-sm">
          <div className="flex items-center mb-6">
            <TrendingUp className="w-6 h-6 text-uai-teal mr-2" />
            <h2 className="text-lg font-semibold">Valores por Dia</h2>
          </div>
          <Bar data={valueData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default Stats;