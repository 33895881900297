import React, { useState } from 'react';
import { Bell, ArrowLeft } from 'lucide-react';

const Notifications: React.FC = () => {
  const [settings, setSettings] = useState({
    rechargeReminders: true,
    lowBalanceAlerts: true,
    promotionalOffers: false,
    emailNotifications: true,
    pushNotifications: true
  });

  const handleChange = (key: keyof typeof settings) => {
    setSettings(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <div>
      <div className="flex items-center mb-6">
        <button 
          onClick={() => window.history.back()}
          className="mr-4 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="w-6 h-6" />
        </button>
        <h1 className="text-2xl font-bold">Notificações</h1>
      </div>

      <div className="bg-white rounded-2xl shadow-sm divide-y">
        <div className="p-6">
          <div className="flex items-center mb-4">
            <Bell className="w-6 h-6 text-uai-teal mr-2" />
            <h2 className="text-lg font-semibold">Preferências de Notificação</h2>
          </div>
          
          <div className="space-y-6">
            {Object.entries(settings).map(([key, value]) => (
              <div key={key} className="flex items-center justify-between">
                <div>
                  <p className="font-medium">{
                    key === 'rechargeReminders' ? 'Lembretes de recarga' :
                    key === 'lowBalanceAlerts' ? 'Alertas de saldo baixo' :
                    key === 'promotionalOffers' ? 'Ofertas promocionais' :
                    key === 'emailNotifications' ? 'Notificações por email' :
                    'Notificações push'
                  }</p>
                  <p className="text-sm text-gray-500">{
                    key === 'rechargeReminders' ? 'Receba lembretes quando sua recarga estiver próxima do vencimento' :
                    key === 'lowBalanceAlerts' ? 'Seja notificado quando seu saldo estiver baixo' :
                    key === 'promotionalOffers' ? 'Receba ofertas e promoções exclusivas' :
                    key === 'emailNotifications' ? 'Receba notificações por email' :
                    'Receba notificações no seu dispositivo'
                  }</p>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={value}
                    onChange={() => handleChange(key as keyof typeof settings)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-uai-light rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-uai-teal"></div>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;