import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, Phone, CreditCard, Wifi, Shield, Smartphone, HelpCircle } from 'lucide-react';

const FAQ: React.FC = () => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  const toggleSection = (title: string) => {
    setOpenSections(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  const toggleItem = (sectionTitle: string, questionIndex: number) => {
    const key = `${sectionTitle}-${questionIndex}`;
    setOpenItems(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const faqSections = [
    {
      icon: <Phone className="w-6 h-6" />,
      title: "Planos e Serviços",
      items: [
        {
          question: "Quais são os planos disponíveis?",
          answer: "Oferecemos planos Controle com diferentes franquias de internet, ligações ilimitadas e benefícios exclusivos. Consulte nossos planos na página inicial para encontrar a melhor opção para você."
        },
        {
          question: "Como funciona o plano Controle?",
          answer: "O plano Controle é uma modalidade que combina o controle de gastos do pré-pago com a comodidade do pós-pago. Você tem uma franquia mensal fixa e pode fazer recargas adicionais se necessário."
        },
        {
          question: "Posso manter meu número atual?",
          answer: "Sim! Você pode trazer seu número atual para a UaiSat através da portabilidade. O processo é gratuito e leva em média 3 dias úteis."
        }
      ]
    },
    {
      icon: <CreditCard className="w-6 h-6" />,
      title: "Pagamentos e Recargas",
      items: [
        {
          question: "Quais são as formas de pagamento aceitas?",
          answer: "Aceitamos cartões de crédito, débito, boleto bancário e PIX para pagamento dos planos e recargas."
        },
        {
          question: "Como faço uma recarga?",
          answer: (
            <div>
              <p>Você pode fazer recargas de três formas:</p>
              <ul className="list-disc pl-6 mt-2 space-y-1">
                <li>Pelo nosso aplicativo</li>
                <li>Pelo site oficial</li>
                <li>Em estabelecimentos credenciados</li>
              </ul>
            </div>
          )
        },
        {
          question: "Qual o valor mínimo para recarga?",
          answer: "O valor mínimo para recarga varia de acordo com o plano contratado. Consulte as opções disponíveis no nosso app ou site."
        }
      ]
    },
    {
      icon: <Wifi className="w-6 h-6" />,
      title: "Internet e Cobertura",
      items: [
        {
          question: "Qual a área de cobertura da UaiSat?",
          answer: "Temos cobertura nacional em mais de 5.000 municípios, com tecnologia 4G em todo o Brasil e 5G nas principais capitais. Verifique a disponibilidade para sua região em nossa ferramenta de consulta de cobertura."
        },
        {
          question: "Como funciona o acúmulo de internet?",
          answer: "Os dados não utilizados no mês são automaticamente acumulados para o mês seguinte, desde que você mantenha seu plano ativo e faça a recarga dentro do prazo."
        },
        {
          question: "O que acontece quando acaba minha internet?",
          answer: "Após o consumo de 100% da franquia, a velocidade é reduzida para 128Kbps, mas você continua navegando sem custos adicionais. Você também pode contratar pacotes adicionais de dados pelo app."
        }
      ]
    },
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Segurança e Privacidade",
      items: [
        {
          question: "Como proteger minha linha contra fraudes?",
          answer: "Recomendamos cadastrar uma senha de segurança, manter seus dados cadastrais atualizados e nunca compartilhar códigos de verificação recebidos por SMS."
        },
        {
          question: "O que fazer em caso de perda ou roubo?",
          answer: "Em caso de perda ou roubo, entre em contato imediatamente com nossa central de atendimento para bloquear sua linha. Você também pode fazer o bloqueio pelo app."
        }
      ]
    },
    {
      icon: <Smartphone className="w-6 h-6" />,
      title: "Aplicativo UaiSat",
      items: [
        {
          question: "Onde posso baixar o aplicativo?",
          answer: "O aplicativo UaiSat está disponível gratuitamente na Google Play Store para dispositivos Android. Em breve também na App Store para iOS."
        },
        {
          question: "O que posso fazer pelo aplicativo?",
          answer: (
            <div>
              <p>Pelo aplicativo você pode:</p>
              <ul className="list-disc pl-6 mt-2 space-y-1">
                <li>Consultar consumo em tempo real</li>
                <li>Fazer recargas</li>
                <li>Contratar pacotes adicionais</li>
                <li>Acessar segunda via de fatura</li>
                <li>Falar com o suporte</li>
                <li>Atualizar seus dados cadastrais</li>
              </ul>
            </div>
          )
        }
      ]
    },
    {
      icon: <HelpCircle className="w-6 h-6" />,
      title: "Suporte",
      items: [
        {
          question: "Como entrar em contato com o suporte?",
          answer: (
            <div>
              <p>Você pode entrar em contato conosco através dos seguintes canais:</p>
              <ul className="list-disc pl-6 mt-2 space-y-1">
                <li>Central de Atendimento: *288 ou 10543</li>
                <li>E-mail: contato@uaimovel.com.br</li>
                <li>Chat no aplicativo</li>
                <li>Redes sociais</li>
              </ul>
            </div>
          )
        },
        {
          question: "Qual o horário de atendimento?",
          answer: "Nossa central de atendimento funciona 24 horas por dia, 7 dias por semana. O atendimento via chat no aplicativo está disponível de segunda a sábado, das 8h às 20h."
        }
      ]
    }
  ];

  return (
    <div className="py-16 px-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-4">Perguntas Frequentes</h1>
        <p className="text-xl text-gray-600 mb-12">
          Encontre respostas para as principais dúvidas sobre nossos serviços
        </p>

        <div className="space-y-6">
          {faqSections.map((section, sectionIndex) => (
            <div
              key={sectionIndex}
              className="bg-white rounded-2xl shadow-sm overflow-hidden"
            >
              <button
                onClick={() => toggleSection(section.title)}
                className="w-full px-6 py-4 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
              >
                <div className="flex items-center">
                  <div className="text-uai-teal mr-3">
                    {section.icon}
                  </div>
                  <h2 className="text-xl font-semibold">{section.title}</h2>
                </div>
                <ChevronDown
                  className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${
                    openSections[section.title] ? 'transform rotate-180' : ''
                  }`}
                />
              </button>

              {openSections[section.title] && (
                <div className="px-6 py-4">
                  <div className="space-y-4">
                    {section.items.map((item, itemIndex) => (
                      <div key={itemIndex} className="border-b border-gray-100 last:border-0">
                        <button
                          onClick={() => toggleItem(section.title, itemIndex)}
                          className="w-full py-4 flex items-center justify-between text-left"
                        >
                          <h3 className="text-lg font-medium pr-8">{item.question}</h3>
                          <ChevronDown
                            className={`w-5 h-5 text-gray-500 flex-shrink-0 transition-transform duration-200 ${
                              openItems[`${section.title}-${itemIndex}`] ? 'transform rotate-180' : ''
                            }`}
                          />
                        </button>
                        
                        {openItems[`${section.title}-${itemIndex}`] && (
                          <div className="pb-4 text-gray-600">
                            {item.answer}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* eSIM Section */}
        <div className="bg-uai-light rounded-2xl p-8 mt-12">
          <div className="flex items-start">
            <Wifi className="w-8 h-8 text-uai-teal mr-4 flex-shrink-0" />
            <div>
              <h2 className="text-2xl font-bold mb-4">Ativação de eSIM</h2>
              <p className="text-gray-600 mb-6">
                Precisa de ajuda para ativar seu eSIM? Temos um guia completo com instruções passo a passo para todos os dispositivos compatíveis.
              </p>
              <Link 
                to="/ativar-esim"
                className="bg-uai-teal hover:bg-uai-dark text-white font-medium py-3 px-6 rounded-lg inline-flex items-center transition-colors"
              >
                <span>Ver guia de ativação</span>
                <ChevronDown className="w-5 h-5 ml-2 transform rotate-[-90deg]" />
              </Link>
            </div>
          </div>
        </div>

        <div className="mt-12 p-6 bg-gray-50 rounded-2xl">
          <h2 className="text-xl font-semibold mb-4">Ainda tem dúvidas?</h2>
          <p className="text-gray-600 mb-6">
            Entre em contato com nossa equipe de suporte. Estamos sempre prontos para ajudar!
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <a
              href="tel:*288"
              className="bg-uai-teal hover:bg-uai-dark text-white px-6 py-3 rounded-xl flex items-center justify-center transition-colors duration-200"
            >
              <Phone className="w-5 h-5 mr-2" />
              <span>Ligar para *288</span>
            </a>
            <a
              href="mailto:contato@uaimovel.com.br"
              className="border border-uai-teal text-uai-teal hover:bg-uai-teal hover:text-white px-6 py-3 rounded-xl flex items-center justify-center transition-colors duration-200"
            >
              <HelpCircle className="w-5 h-5 mr-2" />
              <span>Enviar e-mail</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;